define("boondmanager/settings/bm", ["exports", "boondmanager/utils/bm"], function (_exports, _bm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bm = void 0;
  var bm = _exports.bm = function bm(i18n) {
    return {
      httpVerbsMapper: {
        create: 'POST',
        update: 'PUT',
        read: 'GET',
        delete: 'DELETE'
      },
      absencesreport: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:absencesreport.filters.periods.created')
          }, {
            id: 'plannedPeriod',
            value: i18n.t('config:absencesreport.filters.periods.plannedPeriod')
          }]
        }
      },
      actions: {
        filters: {
          periods: [{
            id: 'started',
            value: i18n.t('config:actions.filters.periods.started')
          }, {
            id: 'created',
            value: i18n.t('config:actions.filters.periods.created')
          }, {
            id: 'updated',
            value: i18n.t('config:actions.filters.periods.updated')
          }]
        },
        templates: {
          state: {
            values: [{
              id: true,
              value: i18n.t('wemailing:templates.state.values.true')
            }, {
              id: false,
              value: i18n.t('wemailing:templates.state.values.false')
            }]
          }
        }
      },
      advantage: {
        creationForm: {
          advantageType: [{
            id: 'non-contractual',
            value: i18n.t('components:modals.createAdvantage.nonContractual')
          }, {
            id: 'contractual',
            value: i18n.t('components:modals.createAdvantage.contractual')
          }]
        }
      },
      airequests: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:airequests.filters.periods.created')
          }]
        }
      },
      appentities: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:appentities.filters.periods.created')
          }, {
            id: 'updated',
            value: i18n.t('config:appentities.filters.periods.updated')
          }]
        }
      },
      appesignature: {
        states: [{
          id: 'signed',
          value: i18n.t('config:timesreport.proofCategory.signedProof'),
          icon: 'bmi-check-circle bm-validate'
        }, {
          id: 'waiting',
          value: i18n.t('config:timesreport.proofCategory.signatureRequested'),
          icon: 'bmi-hourglass-half'
        }, {
          id: 'created',
          value: i18n.t('config:timesreport.proofCategory.signatureRequested'),
          icon: 'bmi-hourglass-half'
        }]
      },
      agency: {
        activityExpenses: {
          timesreports: {
            workunitTypes: {
              activityType: {
                options: [{
                  id: 'production',
                  value: i18n.t('advmodprojects:view.orders.production')
                }, {
                  id: 'absence',
                  value: i18n.t('houraccounts:table.absence.title')
                }, {
                  id: 'internal',
                  value: i18n.t('wextractpayroll:internalTimes')
                }, {
                  id: 'exceptionalTime',
                  value: i18n.t('config:agency.activityExpenses.timesreports.workunitTypes.activityType.options.exceptionalTime')
                }, {
                  id: 'exceptionalCalendar',
                  value: i18n.t('config:agency.activityExpenses.timesreports.workunitTypes.activityType.options.exceptionalCalendar')
                }]
              }
            },
            timesAlerts: {
              options: [{
                id: 'moreThanNumberOfWorkingDays',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.moreThanNumberOfWorkingDays')
              }, {
                id: 'moreThanNumberOfWorkingDaysInsideContractDates',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.moreThanNumberOfWorkingDaysInsideContractDates')
              }, {
                id: 'lessThanNumberOfWorkingDaysInsideContractDates',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.lessThanNumberOfWorkingDaysInsideContractDates')
              }, {
                id: 'atLeastOneAbsenceQuotaExceeded',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.atLeastOneAbsenceQuotaExceeded')
              }, {
                id: 'wrongAbsences',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.wrongAbsences')
              }, {
                id: 'outsideContractDates',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.outsideContractDates')
              }, {
                id: 'workplaceTimesMoreThanNumberOfWorkingDays',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.workplaceTimesMoreThanNumberOfWorkingDays')
              }, {
                id: 'workplaceTimesLessThanNumberOfWorkingDays',
                value: i18n.t('config:agency.activityExpenses.timesreports.timesAlerts.options.workplaceTimesLessThanNumberOfWorkingDays')
              }]
            }
          },
          absencesaccounts: {
            absencesCalculationMethod: {
              options: [{
                id: 'takenUntilN-1AndAskedFromN',
                value: i18n.t('config:agency.activityExpenses.absencesaccounts.absencesCalculationMethod.options.takenUntilN-1AndAskedFromN')
              }, {
                id: 'totalTakenAndAsked',
                value: i18n.t('config:agency.activityExpenses.absencesaccounts.absencesCalculationMethod.options.totalTakenAndAsked')
              }]
            },
            triggersAccounts: {
              useBeingAcquired: {
                options: [{
                  id: 'inactive',
                  value: i18n.t('survey:answers.no')
                }, {
                  id: 'allowTakenAbsencesOnBeingAcquired',
                  value: i18n.t('config:agency.activityExpenses.absencesaccounts.triggersAccounts.useBeingAcquired.options.allowTakenAbsencesOnBeingAcquired')
                }, {
                  id: 'forbidTakenAbsencesOnBeingAcquired',
                  value: i18n.t('config:agency.activityExpenses.absencesaccounts.triggersAccounts.useBeingAcquired.options.forbidTakenAbsencesOnBeingAcquired')
                }]
              }
            }
          },
          creationAndMailingDate: {
            options: [{
              id: 'inactive',
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.inactive')
            }, {
              id: 1,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.1')
            }, {
              id: 2,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.2')
            }, {
              id: 3,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.3')
            }, {
              id: 4,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.4')
            }, {
              id: 5,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.5')
            }, {
              id: 6,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.6')
            }, {
              id: 7,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.7')
            }, {
              id: 8,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.8')
            }, {
              id: 9,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.9')
            }, {
              id: 10,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.10')
            }, {
              id: 11,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.11')
            }, {
              id: 12,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.12')
            }, {
              id: 13,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.13')
            }, {
              id: 14,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.14')
            }, {
              id: 15,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.15')
            }, {
              id: 16,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.16')
            }, {
              id: 17,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.17')
            }, {
              id: 18,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.18')
            }, {
              id: 19,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.19')
            }, {
              id: 20,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.20')
            }, {
              id: 21,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.21')
            }, {
              id: 22,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.22')
            }, {
              id: 23,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.23')
            }, {
              id: 24,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.24')
            }, {
              id: 25,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.25')
            }, {
              id: 26,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.26')
            }, {
              id: 27,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.27')
            }, {
              id: 28,
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.28')
            }, {
              id: 'lastDayOfMonth',
              value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.lastDayOfMonth')
            }]
          },
          ocrcategory: {
            options: [{
              id: 0,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.0')
            }, {
              id: 1,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.1')
            }, {
              id: 2,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.2')
            }, {
              id: 3,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.3')
            }, {
              id: 4,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.4')
            }, {
              id: 5,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.5')
            }, {
              id: 6,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.6')
            }, {
              id: -1,
              value: i18n.t('config:agency.activityExpenses.ocrcategory.options.-1')
            }]
          },
          workplaceTypes: {
            types: {
              options: [{
                id: 'customer',
                value: i18n.t('config:agency.activityExpenses.workplaceTypes.types.options.customer')
              }, {
                id: 'agency',
                value: i18n.t('intranetaccounts:view.agency')
              }, {
                id: 'remote',
                value: i18n.t('config:agency.activityExpenses.workplaceTypes.types.options.remote')
              }]
            }
          }
        },
        billing: {
          invoices: {
            advancedParameters: {
              warning: [{
                id: 0,
                value: i18n.t('config:agency.billing.invoices.advancedParameters.warning.0')
              }],
              qrInvoiceTypes: [{
                id: 0,
                value: i18n.t('config:agency.billing.invoices.advancedParameters.qrInvoiceTypes.0')
              }]
            },
            invoicesMailSenderAdditionalRecipients: {
              cc: {
                options: [{
                  id: 'currentManager',
                  value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.currentManager')
                }, {
                  id: 'mainManager',
                  value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.mainManager')
                }, {
                  id: 'projectManager',
                  value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.projectManager')
                }]
              },
              bcc: {
                options: [{
                  id: 'currentManager',
                  value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.currentManager')
                }, {
                  id: 'mainManager',
                  value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.mainManager')
                }, {
                  id: 'projectManager',
                  value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.projectManager')
                }]
              }
            },
            invoicesMailSenderType: {
              options: [{
                id: 'mainManager',
                value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.currentManager')
              }, {
                id: 'customized',
                value: i18n.t('config:agency.billing.invoices.invoicesMailSenderAdditionalRecipients.cc.options.customized')
              }]
            }
          },
          orders: {
            ordersExpensesReinvoicedIncludingOrExcludingTax: {
              options: [{
                id: 'includingTax',
                value: i18n.t('common:financial.inclTax.abbr')
              }, {
                id: 'excludingTax',
                value: i18n.t('common:financial.exclTax.abbr')
              }]
            }
          }
        },
        projects: {
          resourcePlanner: {
            jDay: {
              options: [{
                id: 'actualDay',
                value: i18n.t('config:agency.projects.resourcePlanner.jDay.options.actualDay')
              }, {
                id: 1,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.1')
              }, {
                id: 2,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.2')
              }, {
                id: 3,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.3')
              }, {
                id: 4,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.4')
              }, {
                id: 5,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.5')
              }, {
                id: 6,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.6')
              }, {
                id: 7,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.7')
              }, {
                id: 8,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.8')
              }, {
                id: 9,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.9')
              }, {
                id: 10,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.10')
              }, {
                id: 11,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.11')
              }, {
                id: 12,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.12')
              }, {
                id: 13,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.13')
              }, {
                id: 14,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.14')
              }, {
                id: 15,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.15')
              }, {
                id: 16,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.16')
              }, {
                id: 17,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.17')
              }, {
                id: 18,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.18')
              }, {
                id: 19,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.19')
              }, {
                id: 20,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.20')
              }, {
                id: 21,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.21')
              }, {
                id: 22,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.22')
              }, {
                id: 23,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.23')
              }, {
                id: 24,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.24')
              }, {
                id: 25,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.25')
              }, {
                id: 26,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.26')
              }, {
                id: 27,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.27')
              }, {
                id: 28,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.28')
              }, {
                id: 'lastDayOfMonth',
                value: i18n.t('config:agency.projects.resourcePlanner.jDay.options.lastDayOfMonth')
              }]
            }
          }
        },
        resources: {
          contracts: {
            contractsSalaryType: {
              options: [{
                id: 'monthly',
                value: i18n.t('wbackupbdd:settings.frequency.monthly.label')
              }, {
                id: 'annual',
                value: i18n.t('components:bmFieldRowAdvantage.advantageTypesFrequency_annual')
              }]
            }
          },
          advantageTypes: {
            frequency: {
              options: [{
                id: 'daily',
                value: i18n.t('config:agency.resources.advantageTypes.frequency.options.daily')
              }, {
                id: 'monthly',
                value: i18n.t('extractbi:frequency.typeOf.options.monthly')
              }, {
                id: 'quarterly',
                value: i18n.t('config:agency.resources.advantageTypes.frequency.options.quarterly')
              }, {
                id: 'semiAnnual',
                value: i18n.t('config:agency.resources.advantageTypes.frequency.options.semiAnnual')
              }, {
                id: 'annual',
                value: i18n.t('config:agency.resources.advantageTypes.frequency.options.annual')
              }]
            }
          },
          exceptionalScales: {
            resetScales: {
              scales: [{
                name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.0.name'),
                activityType: i18n.t('agency.activityExpenses.timesreports.workunitTypes.activityType.options.4.id'),
                rules: [{
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.0.rules.0.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 0
                }, {
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.0.rules.1.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 0
                }, {
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.0.rules.2.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 0
                }, {
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.0.rules.3.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 0
                }]
              }, {
                name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.1.name'),
                activityType: i18n.t('config:agency.resources.exceptionalScales.resetScales.scales.activityType'),
                rules: [{
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.1.rules.0.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 150
                }, {
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.1.rules.1.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 125
                }, {
                  name: i18n.t('agency.resources.exceptionalScales.resetScales.scales.1.rules.2.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 250
                }, {
                  name: i18n.t('config:agency.resources.exceptionalScales.resetScales.scales.0.rules.name'),
                  priceExcludingTaxOrPriceRate: 0,
                  grossCostOrSalaryRate: 225
                }]
              }]
            }
          }
        }
      },
      agencies: {
        expensesPeriodicity: [{
          id: 'daily',
          value: i18n.t('components:bmFieldRowAdvantage.advantageTypesFrequency_daily')
        }, {
          id: 'monthly',
          value: i18n.t('wbackupbdd:settings.frequency.monthly.label')
        }]
      },
      alerts: {
        days: [{
          id: 1,
          value: i18n.t('alerts:view.dailyDays.options.monday')
        }, {
          id: 2,
          value: i18n.t('alerts:view.dailyDays.options.tuesday')
        }, {
          id: 3,
          value: i18n.t('alerts:view.dailyDays.options.wednesday')
        }, {
          id: 4,
          value: i18n.t('alerts:view.dailyDays.options.thursday')
        }, {
          id: 5,
          value: i18n.t('alerts:view.dailyDays.options.friday')
        }, {
          id: 6,
          value: i18n.t('alerts:view.dailyDays.options.saturday')
        }, {
          id: 0,
          value: i18n.t('alerts:view.dailyDays.options.sunday')
        }],
        modules: [{
          id: 'actions',
          value: i18n.t('alerts:view.modules.actions')
        }, {
          id: 'activityExpenses',
          value: i18n.t('alerts:view.modules.activityExpenses')
        }, {
          id: 'candidates',
          value: i18n.t('alerts:view.modules.candidates')
        }, {
          id: 'resources',
          value: i18n.t('alerts:view.modules.resources')
        }, {
          id: 'opportunities',
          value: i18n.t('alerts:view.modules.opportunities')
        }, {
          id: 'positionings',
          value: i18n.t('alerts:view.modules.positionings')
        }, {
          id: 'projects',
          value: i18n.t('alerts:view.modules.projects')
        }, {
          id: 'billing',
          value: i18n.t('alerts:view.modules.billing')
        }, {
          id: 'purchases',
          value: i18n.t('alerts:view.modules.purchases')
        }],
        reports: [{
          id: 'daily',
          value: i18n.t('alerts:view.reports.daily')
        }, {
          id: 'weekly',
          value: i18n.t('alerts:view.reports.weekly')
        }],
        indicators: {
          actions: [{
            id: 'actionsUpcoming',
            value: i18n.t('alerts:view.indicators.actionsUpcoming.name')
          }, {
            id: 'actionsOfTheDay',
            value: i18n.t('alerts:view.indicators.actionsOfTheDay.name')
          }, {
            id: 'actionsLate',
            value: i18n.t('alerts:view.indicators.actionsLate.name')
          }],
          activityExpenses: [{
            id: 'timesReportsWithNoValidation',
            value: i18n.t('alerts:view.indicators.timesReportsWithNoValidation.name')
          }, {
            id: 'expensesReportsWithNoValidation',
            value: i18n.t('alerts:view.indicators.expensesReportsWithNoValidation.name')
          }, {
            id: 'absencesReportsWithNoValidation',
            value: i18n.t('alerts:view.indicators.absencesReportsWithNoValidation.name')
          }],
          candidates: [{
            id: 'candidatesWithNoAction',
            value: i18n.t('alerts:view.indicators.candidatesWithNoAction.name')
          }, {
            id: 'candidatesNotUpdated',
            value: i18n.t('alerts:view.indicators.candidatesNotUpdated.name')
          }],
          resources: [{
            id: 'resourcesWithNoAction',
            value: i18n.t('alerts:view.indicators.resourcesWithNoAction.name')
          }, {
            id: 'resourcesWithNoContracts',
            value: i18n.t('alerts:view.indicators.resourcesWithNoContracts.name')
          }, {
            id: 'resourcesWithNoMoreContracts',
            value: i18n.t('alerts:view.indicators.resourcesWithNoMoreContracts.name')
          }, {
            id: 'resourcesProbationaryDateUpcoming',
            value: i18n.t('alerts:view.indicators.resourcesProbationaryDateUpcoming.name')
          }, {
            id: 'resourcesProbationaryDateToday',
            value: i18n.t('alerts:view.indicators.resourcesProbationaryDateToday.name')
          }, {
            id: 'resourcesProbationaryDateTerminated',
            value: i18n.t('alerts:view.indicators.resourcesProbationaryDateTerminated.name')
          }, {
            id: 'contractsEndedUpcoming',
            value: i18n.t('alerts:view.indicators.contractsEndedUpcoming.name')
          }, {
            id: 'contractsEndedToday',
            value: i18n.t('alerts:view.indicators.contractsEndedToday.name')
          }, {
            id: 'contractsEnded',
            value: i18n.t('alerts:view.indicators.contractsEnded.name')
          }, {
            id: 'resourcesWithOverlappedContracts',
            value: i18n.t('alerts:view.indicators.resourcesWithOverlappedContracts.name')
          }, {
            id: 'resourcesWithNoContractEndDate',
            value: i18n.t('alerts:view.indicators.resourcesWithNoContractEndDate.name')
          }, {
            id: 'resourcesArchivedWithActivatedIntranet',
            value: i18n.t('alerts:view.indicators.resourcesArchivedWithActivatedIntranet.name')
          }, {
            id: 'resourcesWithFollowedDocuments',
            value: i18n.t('alerts:view.indicators.resourcesWithFollowedDocuments.name')
          }],
          opportunities: [{
            id: 'opportunitiesWithNoAction',
            value: i18n.t('alerts:view.indicators.opportunitiesWithNoAction.name')
          }],
          positionings: [{
            id: 'positioningsNotUpdated',
            value: i18n.t('alerts:view.indicators.positioningsNotUpdated.name')
          }],
          projects: [{
            id: 'projectsWithNoAction',
            value: i18n.t('alerts:view.indicators.projectsWithNoAction.name')
          }, {
            id: 'projectsEndedUpcomingOrAlreadyEnded',
            value: i18n.t('alerts:view.indicators.projectsEndedUpcomingOrAlreadyEnded.name')
          }, {
            id: 'projectsEndedUpcoming',
            value: i18n.t('alerts:view.indicators.projectsEndedUpcoming.name')
          }, {
            id: 'projectsEndedToday',
            value: i18n.t('alerts:view.indicators.projectsEndedToday.name')
          }, {
            id: 'projectsEnded',
            value: i18n.t('alerts:view.indicators.projectsEnded.name')
          }, {
            id: 'deliveriesEndedUpcoming',
            value: i18n.t('alerts:view.indicators.deliveriesEndedUpcoming.name')
          }, {
            id: 'deliveriesEndedToday',
            value: i18n.t('alerts:view.indicators.deliveriesEndedToday.name')
          }, {
            id: 'deliveriesEnded',
            value: i18n.t('alerts:view.indicators.deliveriesEnded.name')
          }, {
            id: 'deliveriesNotEntirelyCoveredByContracts',
            value: i18n.t('alerts:view.indicators.deliveriesNotEntirelyCoveredByContracts.name')
          }],
          billing: [{
            id: 'projectsWithNoOrderNotArchived',
            value: i18n.t('alerts:view.indicators.projectsWithNoOrderNotArchived.name')
          }, {
            id: 'projectsWithSignedTurnoverGreaterThanOrderedTurnover',
            value: i18n.t('alerts:view.indicators.projectsWithSignedTurnoverGreaterThanOrderedTurnover.name')
          }, {
            id: 'ordersWithNoBillingDetail',
            value: i18n.t('alerts:view.indicators.ordersWithNoBillingDetail.name')
          }, {
            id: 'ordersWithNoDeliveryCorrelated',
            value: i18n.t('alerts:view.indicators.ordersWithNoDeliveryCorrelated.name')
          }, {
            id: 'ordersWithInvoicedTurnoverGreaterThanOrderedTurnover',
            value: i18n.t('alerts:view.indicators.ordersWithInvoicedTurnoverGreaterThanOrderedTurnover.name')
          }, {
            id: 'ordersWithSchedulesAmountDifferentFromOrderedTurnover',
            value: i18n.t('alerts:view.indicators.ordersWithSchedulesAmountDifferentFromOrderedTurnover.name')
          }, {
            id: 'invoicesWithNoAction',
            value: i18n.t('alerts:view.indicators.invoicesWithNoAction.name')
          }, {
            id: 'invoicesWithDatesOfPaymentsUpcoming',
            value: i18n.t('alerts:view.indicators.invoicesWithDatesOfPaymentsUpcoming.name')
          }, {
            id: 'invoicesWithDatesOfPaymentsIsToday',
            value: i18n.t('alerts:view.indicators.invoicesWithDatesOfPaymentsIsToday.name')
          }, {
            id: 'invoicesWithDatesOfPaymentsPast',
            value: i18n.t('alerts:view.indicators.invoicesWithDatesOfPaymentsPast.name')
          }, {
            id: 'schedulesPastWithNoInvoice',
            value: i18n.t('alerts:view.indicators.schedulesPastWithNoInvoice.name')
          }],
          purchases: [{
            id: 'providerInvoiceWaitingValidation',
            value: i18n.t('alerts:view.indicators.providerInvoiceWaitingValidation.name')
          }]
        }
      },
      app: {
        usersAccess: {
          options: [{
            id: 'allowedManagers',
            value: i18n.t('config:app.usersAccess.options.allowedManagers')
          }, {
            id: 'allManagers',
            value: i18n.t('config:app.usersAccess.options.allManagers')
          }, {
            id: 'allowedManagersAndResources',
            value: i18n.t('config:app.usersAccess.options.allowedManagersAndResources')
          }, {
            id: 'allManagersAndResources',
            value: i18n.t('config:app.usersAccess.options.allManagersAndResources')
          }]
        },
        category: {
          option: [{
            id: 'other',
            value: i18n.t('config:agency.activityExpenses.ocrcategory.options.6')
          }, {
            id: 'certification',
            value: i18n.t('config:app.category.option.certification')
          }, {
            id: 'templates',
            value: i18n.t('config:app.category.option.templates')
          }, {
            id: 'mail',
            value: i18n.t('config:app.category.option.mail')
          }, {
            id: 'calendar',
            value: i18n.t('config:app.category.option.calendar')
          }, {
            id: 'viewer',
            value: i18n.t('config:app.category.option.viewer')
          }, {
            id: 'emailing',
            value: i18n.t('wemailing:title')
          }, {
            id: 'mailAndCalendar',
            value: i18n.t('config:app.category.option.mailAndCalendar')
          }]
        },
        integration: {
          module: {
            option: [{
              id: 'resources',
              value: i18n.t('resources:title')
            }, {
              id: 'candidates',
              value: i18n.t('candidates:title')
            }, {
              id: 'products',
              value: i18n.t('products:title')
            }, {
              id: 'contacts',
              value: i18n.t('contacts:title')
            }, {
              id: 'companies',
              value: i18n.t('companies:title')
            }, {
              id: 'opportunities',
              value: i18n.t('opportunities:title')
            }, {
              id: 'projects',
              value: i18n.t('projects:title')
            }, {
              id: 'purchases',
              value: i18n.t('purchases:title')
            }, {
              id: 'orders',
              value: i18n.t('orders:title')
            }, {
              id: 'invoices',
              value: i18n.t('invoices:title')
            }],
            miscellaneous: [{
              id: 'deliveries',
              value: i18n.t('deliveries:title')
            }]
          },
          tab: {
            option: [{
              id: 'information',
              value: i18n.t('resources:view.tabs.information.title')
            }, {
              id: 'administrative',
              value: i18n.t('resources:view.tabs.administrative.title')
            }, {
              id: 'technicalData',
              value: i18n.t('resources:view.tabs.technicalData.title')
            }]
          },
          typeOf: {
            option: [{
              id: 'iFrame',
              value: i18n.t('config:app.integration.typeOf.option.iFrame')
            }, {
              id: 'module',
              value: i18n.t('config:app.integration.typeOf.option.module')
            }, {
              id: 'moduleNoCode',
              value: i18n.t('config:app.integration.typeOf.option.moduleNoCode')
            }, {
              id: 'sectionNoCode',
              value: i18n.t('config:app.integration.typeOf.option.sectionNoCode')
            }]
          },
          visibility: {
            option: [{
              id: false,
              value: i18n.t('config:app.integration.visibility.option.false')
            }, {
              id: true,
              value: i18n.t('config:app.integration.visibility.option.true')
            }]
          },
          tokenSecurity: {
            option: [{
              id: 'permanent',
              value: i18n.t('config:app.integration.tokenSecurity.option.permanent')
            }, {
              id: 'temporary',
              value: i18n.t('config:app.integration.tokenSecurity.option.temporary')
            }]
          },
          validation: {
            option: [{
              id: false,
              value: i18n.t('config:app.integration.validation.option.false')
            }, {
              id: true,
              value: i18n.t('config:app.integration.validation.option.true')
            }]
          }
        },
        width: {
          option: [{
            id: false,
            value: i18n.t('config:app.width.option.false')
          }, {
            id: true,
            value: i18n.t('config:app.width.option.true')
          }]
        },
        settingsModule: {
          option: [{
            id: 'resources',
            value: i18n.t('resources:title')
          }, {
            id: 'candidates',
            value: i18n.t('candidates:title')
          }, {
            id: 'products',
            value: i18n.t('products:title')
          }, {
            id: 'contacts',
            value: i18n.t('contacts:title')
          }, {
            id: 'companies',
            value: i18n.t('companies:title')
          }, {
            id: 'opportunities',
            value: i18n.t('opportunities:title')
          }, {
            id: 'projects',
            value: i18n.t('projects:title')
          }, {
            id: 'purchases',
            value: i18n.t('purchases:title')
          }]
        },
        triggersSetting: {
          event: {
            option: [{
              id: 'create',
              value: i18n.t('common:actions.create')
            }, {
              id: 'update',
              value: i18n.t('config:app.triggersSetting.event.option.update')
            }, {
              id: 'delete',
              value: i18n.t('organizationcharts:deleteNodeTitle')
            }]
          }
        },
        cardOptions: [{
          id: 'dashboard',
          value: i18n.t('config:app.cardOptions.dashboard'),
          onlyIFrame: true
        }, {
          id: 'purchases/@id',
          value: i18n.t('config:app.cardOptions.purchases/@id')
        }, {
          id: 'purchases/@id/information',
          value: i18n.t('config:app.cardOptions.purchases/@id/information'),
          tab: true
        }, {
          id: 'purchases/@id/payments',
          value: i18n.t('config:app.cardOptions.purchases/@id/payments'),
          tab: true
        }, {
          id: 'actions/@id',
          value: i18n.t('config:app.cardOptions.actions/@id')
        }, {
          id: 'advantages/@id',
          value: i18n.t('config:app.cardOptions.advantages/@id')
        }, {
          id: 'opportunities/@id',
          value: i18n.t('config:app.cardOptions.opportunities/@id')
        }, {
          id: 'opportunities/@id/information',
          value: i18n.t('config:app.cardOptions.opportunities/@id/information'),
          tab: true
        }, {
          id: 'opportunities/@id/actions',
          value: i18n.t('config:app.cardOptions.opportunities/@id/actions'),
          tab: true
        }, {
          id: 'opportunities/@id/positionings',
          value: i18n.t('config:app.cardOptions.opportunities/@id/positionings'),
          tab: true
        }, {
          id: 'opportunities/@id/projects',
          value: i18n.t('config:app.cardOptions.opportunities/@id/projects'),
          tab: true
        }, {
          id: 'candidates/@id',
          value: i18n.t('config:app.cardOptions.candidates/@id')
        }, {
          id: 'candidates/@id/overview',
          value: i18n.t('config:app.cardOptions.candidates/@id/overview'),
          tab: true
        }, {
          id: 'candidates/@id/information',
          value: i18n.t('config:app.cardOptions.candidates/@id/information'),
          tab: true
        }, {
          id: 'candidates/@id/actions',
          value: i18n.t('config:app.cardOptions.candidates/@id/actions'),
          tab: true
        }, {
          id: 'candidates/@id/technical-data',
          value: i18n.t('config:app.cardOptions.candidates/@id/technical-data'),
          tab: true
        }, {
          id: 'candidates/@id/positionings',
          value: i18n.t('config:app.cardOptions.candidates/@id/positionings'),
          tab: true
        }, {
          id: 'candidates/@id/administrative',
          value: i18n.t('config:app.cardOptions.candidates/@id/administrative'),
          tab: true
        }, {
          id: 'orders/@id',
          value: i18n.t('config:app.cardOptions.orders/@id')
        }, {
          id: 'orders/@id/information',
          value: i18n.t('config:app.cardOptions.orders/@id/information'),
          tab: true
        }, {
          id: 'orders/@id/actions',
          value: i18n.t('config:app.cardOptions.orders/@id/actions'),
          tab: true
        }, {
          id: 'orders/@id/invoices',
          value: i18n.t('config:app.cardOptions.orders/@id/invoices'),
          tab: true
        }, {
          id: 'contracts/@id',
          value: i18n.t('config:app.cardOptions.contracts/@id')
        }, {
          id: 'contacts/@id',
          value: i18n.t('config:app.cardOptions.contacts/@id')
        }, {
          id: 'contacts/@id/information',
          value: i18n.t('config:app.cardOptions.contacts/@id/information'),
          tab: true
        }, {
          id: 'contacts/@id/actions',
          value: i18n.t('config:app.cardOptions.contacts/@id/actions'),
          tab: true
        }, {
          id: 'contacts/@id/opportunities',
          value: i18n.t('config:app.cardOptions.contacts/@id/opportunities'),
          tab: true
        }, {
          id: 'contacts/@id/projects',
          value: i18n.t('config:app.cardOptions.contacts/@id/projects'),
          tab: true
        }, {
          id: 'contacts/@id/purchases',
          value: i18n.t('config:app.cardOptions.contacts/@id/purchases'),
          tab: true
        }, {
          id: 'contacts/@id/orders',
          value: i18n.t('config:app.cardOptions.contacts/@id/orders'),
          tab: true
        }, {
          id: 'contacts/@id/invoices',
          value: i18n.t('config:app.cardOptions.contacts/@id/invoices'),
          tab: true
        }, {
          id: 'companies/@id',
          value: i18n.t('config:app.cardOptions.companies/@id')
        }, {
          id: 'companies/@id/information',
          value: i18n.t('config:app.cardOptions.companies/@id/information'),
          tab: true
        }, {
          id: 'companies/@id/contacts',
          value: i18n.t('config:app.cardOptions.companies/@id/contacts'),
          tab: true
        }, {
          id: 'companies/@id/actions',
          value: i18n.t('config:app.cardOptions.companies/@id/actions'),
          tab: true
        }, {
          id: 'companies/@id/opportunities',
          value: i18n.t('config:app.cardOptions.companies/@id/opportunities'),
          tab: true
        }, {
          id: 'companies/@id/projects',
          value: i18n.t('config:app.cardOptions.companies/@id/projects'),
          tab: true
        }, {
          id: 'companies/@id/purchases',
          value: i18n.t('config:app.cardOptions.companies/@id/purchases'),
          tab: true
        }, {
          id: 'companies/@id/orders',
          value: i18n.t('config:app.cardOptions.companies/@id/orders'),
          tab: true
        }, {
          id: 'companies/@id/invoices',
          value: i18n.t('config:app.cardOptions.companies/@id/invoices'),
          tab: true
        }, {
          id: 'absences-reports/@id',
          value: i18n.t('config:app.cardOptions.absences-reports/@id')
        }, {
          id: 'invoices/@id',
          value: i18n.t('config:app.cardOptions.invoices/@id')
        }, {
          id: 'invoices/@id/information',
          value: i18n.t('config:app.cardOptions.invoices/@id/information'),
          tab: true
        }, {
          id: 'invoices/@id/actions',
          value: i18n.t('config:app.cardOptions.invoices/@id/actions'),
          tab: true
        }, {
          id: 'times-reports/@id',
          value: i18n.t('config:app.cardOptions.times-reports/@id')
        }, {
          id: 'deliveries/@id',
          value: i18n.t('config:app.cardOptions.deliveries/@id')
        }, {
          id: 'groupments/@id',
          value: i18n.t('config:app.cardOptions.groupments/@id')
        }, {
          id: 'inactivities/@id',
          value: i18n.t('config:app.cardOptions.inactivities/@id')
        }, {
          id: 'expenses-reports/@id',
          value: i18n.t('config:app.cardOptions.expenses-reports/@id')
        }, {
          id: 'payments/@id',
          value: i18n.t('config:app.cardOptions.payments/@id')
        }, {
          id: 'positionings/@id',
          value: i18n.t('config:app.cardOptions.positionings/@id')
        }, {
          id: 'products/@id',
          value: i18n.t('config:app.cardOptions.products/@id')
        }, {
          id: 'products/@id/information',
          value: i18n.t('config:app.cardOptions.products/@id/information'),
          tab: true
        }, {
          id: 'products/@id/opportunities',
          value: i18n.t('config:app.cardOptions.products/@id/opportunities'),
          tab: true
        }, {
          id: 'products/@id/projects',
          value: i18n.t('config:app.cardOptions.products/@id/projects'),
          tab: true
        }, {
          id: 'projects/@id',
          value: i18n.t('config:app.cardOptions.projects/@id')
        }, {
          id: 'projects/@id/information',
          value: i18n.t('config:app.cardOptions.projects/@id/information'),
          tab: true
        }, {
          id: 'projects/@id/batches-markers',
          value: i18n.t('config:app.cardOptions.projects/@id/batches-markers'),
          tab: true
        }, {
          id: 'projects/@id/actions',
          value: i18n.t('config:app.cardOptions.projects/@id/actions'),
          tab: true
        }, {
          id: 'projects/@id/deliveries-groupments',
          value: i18n.t('config:app.cardOptions.projects/@id/deliveries-groupments'),
          tab: true
        }, {
          id: 'projects/@id/purchases',
          value: i18n.t('config:app.cardOptions.projects/@id/purchases'),
          tab: true
        }, {
          id: 'projects/@id/productivity',
          value: i18n.t('config:app.cardOptions.projects/@id/productivity'),
          tab: true
        }, {
          id: 'projects/@id/orders',
          value: i18n.t('config:app.cardOptions.projects/@id/orders'),
          tab: true
        }, {
          id: 'projects/@id/advantages',
          value: i18n.t('config:app.cardOptions.projects/@id/advantages'),
          tab: true
        }, {
          id: 'resources/@id',
          value: i18n.t('config:app.cardOptions.resources/@id')
        }, {
          id: 'resources/@id/overview',
          value: i18n.t('config:app.cardOptions.resources/@id/overview'),
          tab: true
        }, {
          id: 'resources/@id/information',
          value: i18n.t('config:app.cardOptions.resources/@id/information'),
          tab: true
        }, {
          id: 'resources/@id/actions',
          value: i18n.t('config:app.cardOptions.resources/@id/actions'),
          tab: true
        }, {
          id: 'resources/@id/administrative',
          value: i18n.t('config:app.cardOptions.resources/@id/administrative'),
          tab: true
        }, {
          id: 'resources/@id/positionings',
          value: i18n.t('config:app.cardOptions.resources/@id/positionings'),
          tab: true
        }, {
          id: 'resources/@id/deliveries-inactivities',
          value: i18n.t('config:app.cardOptions.resources/@id/deliveries-inactivities'),
          tab: true
        }, {
          id: 'resources/@id/projects',
          value: i18n.t('config:app.cardOptions.resources/@id/projects'),
          tab: true
        }, {
          id: 'resources/@id/technical-data',
          value: i18n.t('config:app.cardOptions.resources/@id/technical-data'),
          tab: true
        }, {
          id: 'resources/@id/times-reports',
          value: i18n.t('config:app.cardOptions.resources/@id/times-reports'),
          tab: true
        }, {
          id: 'resources/@id/expenses-reports',
          value: i18n.t('config:app.cardOptions.resources/@id/expenses-reports'),
          tab: true
        }, {
          id: 'resources/@id/absences-reports',
          value: i18n.t('config:app.cardOptions.resources/@id/absences-reports'),
          tab: true
        }, {
          id: 'resources/@id/absences-accounts',
          value: i18n.t('config:app.cardOptions.resources/@id/absences-accounts'),
          tab: true
        }, {
          id: 'validations/@id',
          value: i18n.t('config:app.cardOptions.validations/@id'),
          onlyTrigger: true
        }, {
          id: 'apps/quotations/quotations/@id',
          value: i18n.t('config:app.cardOptions.apps/quotations/quotations/@id'),
          onlyTrigger: true
        }],
        searchOptions: [{
          id: 'purchases',
          value: i18n.t('config:app.searchOptions.purchases')
        }, {
          id: 'payments',
          value: i18n.t('config:app.searchOptions.payments')
        }, {
          id: 'actions',
          value: i18n.t('config:app.searchOptions.actions')
        }, {
          id: 'opportunities',
          value: i18n.t('config:app.searchOptions.opportunities')
        }, {
          id: 'candidates',
          value: i18n.t('config:app.searchOptions.candidates')
        }, {
          id: 'invoices',
          value: i18n.t('config:app.searchOptions.invoices')
        }, {
          id: 'orders',
          value: i18n.t('config:app.searchOptions.orders')
        }, {
          id: 'billing-projects-balance',
          value: i18n.t('config:app.searchOptions.billing-projects-balance')
        }, {
          id: 'billing-monthly-balance',
          value: i18n.t('config:app.searchOptions.billing-monthly-balance')
        }, {
          id: 'billing-schedules-balance',
          value: i18n.t('config:app.searchOptions.billing-schedules-balance')
        }, {
          id: 'billing-deliveries-purchases-balance',
          value: i18n.t('config:app.searchOptions.billing-deliveries-purchases-balance')
        }, {
          id: 'contacts',
          value: i18n.t('config:app.searchOptions.contacts')
        }, {
          id: 'companies',
          value: i18n.t('config:app.searchOptions.companies')
        }, {
          id: 'positionings',
          value: i18n.t('config:app.searchOptions.positionings')
        }, {
          id: 'products',
          value: i18n.t('config:app.searchOptions.products')
        }, {
          id: 'projects',
          value: i18n.t('config:app.searchOptions.projects')
        }, {
          id: 'deliveries',
          value: i18n.t('config:app.searchOptions.deliveries')
        }, {
          id: 'resources',
          value: i18n.t('config:app.searchOptions.resources')
        }, {
          id: 'times-reports',
          value: i18n.t('config:app.searchOptions.times-reports')
        }, {
          id: 'expenses-reports',
          value: i18n.t('config:app.searchOptions.expenses-reports')
        }, {
          id: 'absences-reports',
          value: i18n.t('config:app.searchOptions.absences-reports')
        }, {
          id: 'validations',
          value: i18n.t('config:app.searchOptions.validations')
        }]
      },
      globaldata: {
        setting: {
          action: {
            candidate: {
              options: [{
                id: 13,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 0,
                title: i18n.t('config:globaldata.setting.action.opportunity.options.title'),
                help: i18n.t('config:globaldata.setting.action.candidate.options.help'),
                hasMultiCreation: true
              }, {
                id: 1,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }, {
                id: 12,
                title: i18n.t('config:globaldata.setting.action.candidate.options.title')
              }]
            },
            contact: {
              options: [{
                id: 2,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 3,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }, {
                id: 10,
                title: i18n.t('config:globaldata.setting.action.opportunity.options.title'),
                help: i18n.t('config:globaldata.setting.action.candidate.options.help'),
                hasMultiCreation: true
              }, {
                id: 11,
                title: i18n.t('config:globaldata.setting.action.contact.options.title'),
                help: i18n.t('config:globaldata.setting.action.contact.options.help'),
                hasMultiCreation: true
              }]
            },
            resource: {
              options: [{
                id: 4,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 5,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }, {
                id: 8,
                title: i18n.t('config:globaldata.setting.action.opportunity.options.title'),
                help: i18n.t('config:globaldata.setting.action.candidate.options.help'),
                hasMultiCreation: true
              }, {
                id: 9,
                title: i18n.t('config:globaldata.setting.action.contact.options.title'),
                help: i18n.t('config:globaldata.setting.action.contact.options.help'),
                hasMultiCreation: true
              }]
            },
            opportunity: {
              options: [{
                id: 50,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 51,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }, {
                id: 52,
                title: i18n.t('config:globaldata.setting.action.opportunity.options.title'),
                help: i18n.t('config:globaldata.setting.action.candidate.options.help'),
                hasMultiCreation: true
              }]
            },
            project: {
              options: [{
                id: 30,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 31,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }, {
                id: 32,
                title: i18n.t('config:globaldata.setting.action.contact.options.title'),
                help: i18n.t('config:globaldata.setting.action.contact.options.help'),
                hasMultiCreation: true
              }, {
                id: 90,
                title: i18n.t('config:globaldata.setting.action.project.options.title'),
                help: i18n.t('config:globaldata.setting.action.project.options.help'),
                hasAction: true
              }]
            },
            order: {
              options: [{
                id: 20,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 21,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }]
            },
            invoice: {
              options: [{
                id: 70,
                title: i18n.t('wextractpayroll:payrollComments')
              }, {
                id: 71,
                title: i18n.t('config:globaldata.setting.action.order.options.title'),
                isTodo: true,
                isDefault: true
              }]
            }
          },
          state: {
            candidate: {
              options: [{
                id: 0,
                title: i18n.t('common:setting.state.candidate.options.0.title'),
                isDefault: true
              }, {
                id: 3,
                title: i18n.t('config:globaldata.setting.state.candidate.options.title'),
                help: i18n.t('config:globaldata.setting.state.candidate.options.help'),
                hasAction: true
              }]
            },
            contact: {
              options: [{
                id: 0,
                title: i18n.t('config:globaldata.setting.state.contact.options.title'),
                isDefault: true
              }]
            },
            company: {
              options: [{
                id: 0,
                title: i18n.t('config:globaldata.setting.state.company.options.title'),
                isDefault: true
              }]
            },
            resource: {
              options: [{
                id: 1,
                title: i18n.t('config:globaldata.setting.state.product.options.title'),
                isDefault: true
              }, {
                id: 0,
                title: i18n.t('config:globaldata.setting.state.resource.options.title'),
                help: i18n.t('config:globaldata.setting.state.resource.options.help'),
                hasAction: true
              }]
            },
            product: {
              options: [{
                id: 1,
                title: i18n.t('config:globaldata.setting.state.product.options.title'),
                isDefault: true
              }]
            },
            opportunity: {
              options: [{
                id: 0,
                title: i18n.t('config:globaldata.setting.state.product.options.title'),
                isDefault: true
              }, {
                id: 1,
                title: i18n.t('config:globaldata.setting.state.opportunity.options.title'),
                help: i18n.t('config:globaldata.setting.state.opportunity.options.help'),
                hasAction: true
              }]
            },
            positioning: {
              options: [{
                id: 0,
                title: i18n.t('common:setting.state.positioning.options.0.title'),
                isDefault: true
              }, {
                id: 2,
                title: i18n.t('config:globaldata.setting.state.opportunity.options.title'),
                help: i18n.t('config:globaldata.setting.state.positioning.options.help'),
                hasAction: true
              }]
            },
            project: {
              options: [{
                id: 1,
                title: i18n.t('config:globaldata.setting.state.product.options.title'),
                isDefault: true
              }]
            },
            delivery: {
              options: [{
                id: 0,
                title: i18n.t('common:setting.state.delivery.options.0.title'),
                isDefault: true
              }, {
                id: 1,
                title: i18n.t('wplanproduction:productionPlansState.forecastDelivery'),
                help: i18n.t('config:globaldata.setting.state.delivery.options.help'),
                hasAction: true
              }]
            },
            purchase: {
              options: [{
                id: 1,
                title: i18n.t('dataclosing:view.tabs.expensesreports.activityExpenses.state.validated'),
                isDefault: true
              }, {
                id: 9,
                title: i18n.t('config:globaldata.setting.state.purchase.options.title'),
                help: i18n.t('config:globaldata.setting.state.purchase.options.help'),
                hasAction: true
              }]
            },
            payment: {
              options: [{
                id: 0,
                title: i18n.t('config:globaldata.setting.state.purchase.options.title'),
                help: i18n.t('common:setting.state.payment.options.0.help'),
                isDefault: true,
                hasAction: true
              }, {
                id: 2,
                title: i18n.t('config:globaldata.setting.state.payment.options.title'),
                help: i18n.t('config:globaldata.setting.state.payment.options.help'),
                hasAction: true
              }]
            },
            order: {
              options: [{
                id: 1,
                title: i18n.t('config:globaldata.setting.state.product.options.title'),
                isDefault: true
              }]
            },
            invoice: {
              options: [{
                id: 0,
                title: i18n.t('advmodcandidates:search.columns.values.creationDate'),
                isDefault: true
              }, {
                id: 3,
                title: i18n.t('config:globaldata.setting.state.invoice.options.title'),
                help: i18n.t('config:globaldata.setting.state.invoice.options.help'),
                hasAction: true
              }]
            },
            quotation: {
              options: [{
                id: 0,
                title: i18n.t('advmodcandidates:search.columns.values.creationDate'),
                isDefault: true
              }, {
                id: 7,
                title: i18n.t('common:setting.state.quotation.options.1.title'),
                help: i18n.t('common:setting.state.quotation.options.1.help'),
                hasAction: true
              }, {
                id: 8,
                title: i18n.t('config:globaldata.setting.state.quotation.options.title'),
                help: i18n.t('config:globaldata.setting.state.quotation.options.help'),
                hasAction: true
              }]
            },
            providerinvoice: {
              options: [{
                id: 0,
                isDefault: true
              }, {
                id: 1,
                title: i18n.t('common:setting.state.providerinvoice.options.1.title'),
                help: i18n.t('common:setting.state.providerinvoice.options.1.help'),
                hasAction: true
              }, {
                id: 2,
                title: i18n.t('common:setting.state.providerinvoice.options.2.title'),
                help: i18n.t('common:setting.state.providerinvoice.options.2.help'),
                hasAction: true
              }, {
                id: 3,
                title: i18n.t('common:setting.state.providerinvoice.options.3.title'),
                help: i18n.t('common:setting.state.providerinvoice.options.3.help'),
                hasAction: true
              }]
            }
          },
          currency: {
            options: [{
              id: 0,
              title: i18n.t('config:globaldata.setting.currency.options.title'),
              help: i18n.t('config:globaldata.setting.currency.options.help')
            }],
            selectOptions: [{
              value: i18n.t('config:globaldata.setting.currency.selectOptions.EUR'),
              id: 'EUR',
              symbol: '€'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.USD'),
              id: 'USD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GBP'),
              id: 'GBP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AFN'),
              id: 'AFN',
              symbol: '؋'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ALL'),
              id: 'ALL',
              symbol: 'Lek'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.DZD'),
              id: 'DZD',
              symbol: 'DA'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AOA'),
              id: 'AOA',
              symbol: 'Kz'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.XCD'),
              id: 'XCD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ARS'),
              id: 'ARS',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AMD'),
              id: 'AMD',
              symbol: '֏'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AWG'),
              id: 'AWG',
              symbol: 'ƒ'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AZN'),
              id: 'AZN',
              symbol: '₼'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BSD'),
              id: 'BSD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BHD'),
              id: 'BHD',
              symbol: 'BD'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BDT'),
              id: 'BDT',
              symbol: '৳'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BBD'),
              id: 'BBD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BYN'),
              id: 'BYN',
              symbol: 'Br'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BZD'),
              id: 'BZD',
              symbol: 'BZ$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.XOF'),
              id: 'XOF',
              symbol: 'FCFA'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.XAF'),
              id: 'XAF',
              symbol: 'FCFA'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BMD'),
              id: 'BMD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.INR'),
              id: 'INR',
              symbol: '₹'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BTN'),
              id: 'BTN',
              symbol: 'Nu'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BOB'),
              id: 'BOB',
              symbol: '$b'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BAM'),
              id: 'BAM',
              symbol: 'KM'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BWP'),
              id: 'BWP',
              symbol: 'P'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.NOK'),
              id: 'NOK',
              symbol: 'Kr'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BRL'),
              id: 'BRL',
              symbol: 'R$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BND'),
              id: 'BND',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BGN'),
              id: 'BGN',
              symbol: 'лв'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.BIF'),
              id: 'BIF',
              symbol: 'Fbu'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CVE'),
              id: 'CVE',
              symbol: 'CVE'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KHR'),
              id: 'KHR',
              symbol: '៛'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CAD'),
              id: 'CAD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CNY'),
              id: 'CNY',
              symbol: '¥'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KYD'),
              id: 'KYD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CLP'),
              id: 'CLP',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CLF'),
              id: 'CLF',
              symbol: 'UF'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.COP'),
              id: 'COP',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KMF'),
              id: 'KMF',
              symbol: 'CF'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CDF'),
              id: 'CDF',
              symbol: 'FC'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.NZD'),
              id: 'NZD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CRC'),
              id: 'CRC',
              symbol: '₡'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.HRK'),
              id: 'HRK',
              symbol: 'Kn'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CUP'),
              id: 'CUP',
              symbol: '₱'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ANG'),
              id: 'ANG',
              symbol: 'ƒ'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CZK'),
              id: 'CZK',
              symbol: 'Kč'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.DKK'),
              id: 'DKK',
              symbol: 'Kr'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.DJF'),
              id: 'DJF',
              symbol: 'DJF'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.DOP'),
              id: 'DOP',
              symbol: 'RD$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.EGP'),
              id: 'EGP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SVC'),
              id: 'SVC',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ERN'),
              id: 'ERN',
              symbol: 'Nkf'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SZL'),
              id: 'SZL',
              symbol: 'L'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ETB'),
              id: 'ETB',
              symbol: 'Br'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.FKP'),
              id: 'FKP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.FJD'),
              id: 'FJD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.XPF'),
              id: 'XPF',
              symbol: 'F'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GMD'),
              id: 'GMD',
              symbol: 'D'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GEL'),
              id: 'GEL',
              symbol: '₾'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GHS'),
              id: 'GHS',
              symbol: '¢'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GIP'),
              id: 'GIP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GTQ'),
              id: 'GTQ',
              symbol: 'Q'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GNF'),
              id: 'GNF',
              symbol: 'GNF'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.GYD'),
              id: 'GYD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.HTG'),
              id: 'HTG',
              symbol: 'G'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.HNL'),
              id: 'HNL',
              symbol: 'L'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.HKD'),
              id: 'HKD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.HUF'),
              id: 'HUF',
              symbol: 'Ft'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ISK'),
              id: 'ISK',
              symbol: 'Kr'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.IDR'),
              id: 'IDR',
              symbol: 'Rp'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.IRR'),
              id: 'IRR',
              symbol: '﷼'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.IQD'),
              id: 'IQD',
              symbol: 'د.ع'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ILS'),
              id: 'ILS',
              symbol: '₪'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.JMD'),
              id: 'JMD',
              symbol: 'J$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.JPY'),
              id: 'JPY',
              symbol: '¥'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.JOD'),
              id: 'JOD',
              symbol: 'د.أ'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KZT'),
              id: 'KZT',
              symbol: 'лв'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KES'),
              id: 'KES',
              symbol: 'Ksh'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KPW'),
              id: 'KPW',
              symbol: '₩'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KRW'),
              id: 'KRW',
              symbol: '₩'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KWD'),
              id: 'KWD',
              symbol: 'د.ك'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.KGS'),
              id: 'KGS',
              symbol: 'лв'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.LAK'),
              id: 'LAK',
              symbol: '₭'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.LBP'),
              id: 'LBP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.LSL'),
              id: 'LSL',
              symbol: 'L'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ZAR'),
              id: 'ZAR',
              symbol: 'R'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.LRD'),
              id: 'LRD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.LYD'),
              id: 'LYD',
              symbol: 'ل.د'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.CHF'),
              id: 'CHF',
              symbol: 'CHF'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MOP'),
              id: 'MOP',
              symbol: 'MOP$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MKD'),
              id: 'MKD',
              symbol: 'ден'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MGA'),
              id: 'MGA',
              symbol: 'Ar'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MWK'),
              id: 'MWK',
              symbol: 'K'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MYR'),
              id: 'MYR',
              symbol: 'RM'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MVR'),
              id: 'MVR',
              symbol: 'Rf'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MRU'),
              id: 'MRU',
              symbol: 'أ.م.'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MUR'),
              id: 'MUR',
              symbol: '₨'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MXN'),
              id: 'MXN',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MDL'),
              id: 'MDL',
              symbol: 'L'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MNT'),
              id: 'MNT',
              symbol: '₮'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MAD'),
              id: 'MAD',
              symbol: '.د.م'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MZN'),
              id: 'MZN',
              symbol: 'MT'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.MMK'),
              id: 'MMK',
              symbol: 'K'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.NAD'),
              id: 'NAD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.NPR'),
              id: 'NPR',
              symbol: '₨'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.NIO'),
              id: 'NIO',
              symbol: 'C$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.NGN'),
              id: 'NGN',
              symbol: '₦'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AUD'),
              id: 'AUD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.OMR'),
              id: 'OMR',
              symbol: '﷼'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PKR'),
              id: 'PKR',
              symbol: 'Rs'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PAB'),
              id: 'PAB',
              symbol: '฿'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PGK'),
              id: 'PGK',
              symbol: 'K'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PYG'),
              id: 'PYG',
              symbol: '₲'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PEN'),
              id: 'PEN',
              symbol: 'S/'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PHP'),
              id: 'PHP',
              symbol: '₱'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.PLN'),
              id: 'PLN',
              symbol: 'zł'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.QAR'),
              id: 'QAR',
              symbol: '﷼'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.RMB'),
              id: 'RMB',
              symbol: '¥'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.RON'),
              id: 'RON',
              symbol: 'lei'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.RUB'),
              id: 'RUB',
              symbol: '₽'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.RWF'),
              id: 'RWF',
              symbol: 'FRw'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SHP'),
              id: 'SHP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.WST'),
              id: 'WST',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.STN'),
              id: 'STN',
              symbol: 'ND'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SAR'),
              id: 'SAR',
              symbol: '﷼'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.RSD'),
              id: 'RSD',
              symbol: 'Дин.'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SCR'),
              id: 'SCR',
              symbol: '₨'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SLL'),
              id: 'SLL',
              symbol: 'Le'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SGD'),
              id: 'SGD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.XSU'),
              id: 'XSU',
              symbol: 'Sucre'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SBD'),
              id: 'SBD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SOS'),
              id: 'SOS',
              symbol: 'S'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SSP'),
              id: 'SSP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.LKR'),
              id: 'LKR',
              symbol: '₨'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SDG'),
              id: 'SDG',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SRD'),
              id: 'SRD',
              symbol: '$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SEK'),
              id: 'SEK',
              symbol: 'Kr'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.SYP'),
              id: 'SYP',
              symbol: '£'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TWD'),
              id: 'TWD',
              symbol: 'NT$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TJS'),
              id: 'TJS',
              symbol: 'SM'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TZS'),
              id: 'TZS',
              symbol: 'TSh'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.THB'),
              id: 'THB',
              symbol: '฿'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TOP'),
              id: 'TOP',
              symbol: 'T$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TTD'),
              id: 'TTD',
              symbol: 'TT$'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TND'),
              id: 'TND',
              symbol: 'د.ت'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TRY'),
              id: 'TRY',
              symbol: '₺'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.TMT'),
              id: 'TMT',
              symbol: 'm'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.UGX'),
              id: 'UGX',
              symbol: 'USh'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.UAH'),
              id: 'UAH',
              symbol: '₴'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.AED'),
              id: 'AED',
              symbol: 'د.إ'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.UYU'),
              id: 'UYU',
              symbol: '$U'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.UZS'),
              id: 'UZS',
              symbol: 'лв'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.VUV'),
              id: 'VUV',
              symbol: 'VT'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.VES'),
              id: 'VES',
              symbol: 'Bs'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.VND'),
              id: 'VND',
              symbol: '₫'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.YER'),
              id: 'YER',
              symbol: '﷼'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ZMW'),
              id: 'ZMW',
              symbol: 'K'
            }, {
              value: i18n.t('config:globaldata.setting.currency.selectOptions.ZWL'),
              id: 'ZWL',
              symbol: 'Z$'
            }]
          },
          paymentMethod: {
            options: [{
              id: 10,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.10')
            }, {
              id: 20,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.20')
            }, {
              id: 30,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.30')
            }, {
              id: 42,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.42')
            }, {
              id: 48,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.48')
            }, {
              id: 49,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.49')
            }, {
              id: 57,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.57')
            }, {
              id: 97,
              value: i18n.t('config:globaldata.setting.paymentMethod.options.97')
            }]
          },
          sendingMode: {
            options: [{
              id: 'email',
              value: i18n.t('config:globaldata.setting.sendingMode.options.email')
            }, {
              id: 'choruspro',
              value: i18n.t('config:globaldata.setting.sendingMode.options.choruspro')
            }, {
              id: 'mail',
              value: i18n.t('config:globaldata.setting.sendingMode.options.mail')
            }, {
              id: 'peppol',
              value: i18n.t('config:globaldata.setting.sendingMode.options.peppol')
            }, {
              id: 'custom',
              value: i18n.t('config:globaldata.setting.sendingMode.options.custom')
            }]
          },
          paymentTerm: {
            options: [{
              id: 0,
              value: i18n.t('config:globaldata.setting.paymentTerm.options.0')
            }, {
              id: 1,
              value: i18n.t('config:globaldata.setting.paymentTerm.options.1')
            }, {
              id: 2,
              value: i18n.t('config:globaldata.setting.paymentTerm.options.2')
            }, {
              id: 3,
              value: i18n.t('config:globaldata.setting.paymentTerm.options.3')
            }, {
              id: 4,
              value: i18n.t('config:globaldata.setting.paymentTerm.options.4')
            }]
          },
          typeOf: {
            resource: {
              mode: {
                options: [{
                  id: 'internalStructure',
                  value: i18n.t('config:globaldata.setting.typeOf.resource.mode.options.internalStructure')
                }, {
                  id: 'externalStructure',
                  value: i18n.t('config:globaldata.setting.typeOf.resource.mode.options.externalStructure')
                }, {
                  id: 'internalBillable',
                  value: i18n.t('config:globaldata.setting.typeOf.resource.mode.options.internalBillable')
                }, {
                  id: 'externalBillable',
                  value: i18n.t('config:globaldata.setting.typeOf.resource.mode.options.externalBillable')
                }]
              }
            },
            project: {
              type: {
                options: [{
                  id: 'internal',
                  value: i18n.t('config:globaldata.setting.typeOf.project.type.options.internal')
                }, {
                  id: 'workUnit',
                  value: i18n.t('config:globaldata.setting.typeOf.project.type.options.workUnit')
                }]
              }
            },
            purchase: {
              options: [{
                id: 1,
                title: i18n.t('config:globaldata.setting.typeOf.purchase.options.title')
              }]
            }
          },
          civility: {
            options: [{
              id: 0,
              canDelete: false
            }, {
              id: 1,
              canDelete: false
            }]
          },
          humanResources: {
            others: {
              defaultNationality: {
                options: [{
                  id: 'others',
                  value: i18n.t('components:bmRightsFilters.modal.entity.access.others'),
                  option: [{
                    id: 'mainManagerCountry',
                    value: i18n.t('config:globaldata.setting.humanResources.others.defaultNationality.options.0.option.mainManagerCountry')
                  }]
                }, {
                  id: 'nationalities',
                  value: i18n.t('config:globaldata.setting.humanResources.others.defaultNationality.options.nationalities')
                }]
              }
            }
          },
          displayedColumns: {
            columns: [{
              id: 'title',
              value: i18n.t('advmodcandidates:search.columns.values.title')
            }, {
              id: 'activityAreas',
              value: i18n.t('advmodcandidates:search.columns.values.activityAreas')
            }, {
              id: 'expertiseArea',
              value: i18n.t('advmodcandidates:search.columns.values.expertiseAreas')
            }, {
              id: 'tools',
              value: i18n.t('advmodcandidates:search.columns.values.tools')
            }]
          },
          defaultPositioningSearchModule: {
            options: [{
              id: 'candidates',
              value: i18n.t('config:globaldata.setting.defaultPositioningSearchModule.options.candidates')
            }, {
              id: 'resources',
              value: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources')
            }]
          },
          profitabilityMethodOfCalculating: {
            options: [{
              id: 'brandRate',
              value: i18n.t('config:globaldata.setting.profitabilityMethodOfCalculating.options.brandRate')
            }, {
              id: 'marginRate',
              value: i18n.t('config:globaldata.setting.profitabilityMethodOfCalculating.options.marginRate')
            }]
          },
          scheduleAmount: {
            options: [{
              id: 'orderTurnoverDividedByTheNumberOfSchedules',
              value: i18n.t('config:globaldata.setting.scheduleAmount.options.orderTurnoverDividedByTheNumberOfSchedules')
            }, {
              id: 'aPercentOfTheOrderTurnoverPerSchedule',
              value: i18n.t('config:globaldata.setting.scheduleAmount.options.aPercentOfTheOrderTurnoverPerSchedule')
            }, {
              id: 'sameAmountForEachSchedule',
              value: i18n.t('config:globaldata.setting.scheduleAmount.options.sameAmountForEachSchedule')
            }, {
              id: 'differentAmountForEachSchedule',
              value: i18n.t('config:globaldata.setting.scheduleAmount.options.differentAmountForEachSchedule')
            }]
          },
          scheduleDate: {
            options: [{
              id: '1',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.1')
            }, {
              id: '2',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.2')
            }, {
              id: '3',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.3')
            }, {
              id: '4',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.4')
            }, {
              id: '5',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.5')
            }, {
              id: '6',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.6')
            }, {
              id: '7',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.7')
            }, {
              id: '8',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.8')
            }, {
              id: '9',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.9')
            }, {
              id: '10',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.10')
            }, {
              id: '11',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.11')
            }, {
              id: '12',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.12')
            }, {
              id: '13',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.13')
            }, {
              id: '14',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.14')
            }, {
              id: '15',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.15')
            }, {
              id: '16',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.16')
            }, {
              id: '17',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.17')
            }, {
              id: '18',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.18')
            }, {
              id: '19',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.19')
            }, {
              id: '20',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.20')
            }, {
              id: '21',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.21')
            }, {
              id: '22',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.22')
            }, {
              id: '23',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.23')
            }, {
              id: '24',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.24')
            }, {
              id: '25',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.25')
            }, {
              id: '26',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.26')
            }, {
              id: '27',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.27')
            }, {
              id: '28',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.28')
            }, {
              id: 'lastDayOfMonth',
              value: i18n.t('config:globaldata.setting.scheduleDate.options.lastDayOfMonth')
            }]
          },
          scheduleFrequency: {
            options: [{
              id: 'customized',
              value: i18n.t('config:globaldata.setting.scheduleFrequency.options.customized')
            }, {
              id: 'additionalTurnover',
              value: i18n.t('config:globaldata.setting.scheduleFrequency.options.additionalTurnover')
            }, {
              id: 'monthly',
              value: i18n.t('config:globaldata.setting.scheduleFrequency.options.monthly')
            }, {
              id: 'quarterly',
              value: i18n.t('config:globaldata.setting.scheduleFrequency.options.quarterly')
            }, {
              id: 'semiAnnual',
              value: i18n.t('config:globaldata.setting.scheduleFrequency.options.semiAnnual')
            }, {
              id: 'annual',
              value: i18n.t('config:globaldata.setting.scheduleFrequency.options.annual')
            }]
          }
        }
      },
      importdata: {
        title: 'Importer vos données',
        profile: 'importer vos données',
        emptyValue: 'Valeur vide',
        import: 'Importer',
        unmapped: 'Non mappé',
        unknownData: 'Données non mappées',
        unknownAttribute: "Colonne '{{column}}'",
        notice: 'Les colonnes qui contiennent des données non mappées ne seront pas importés',
        preview: 'Aperçu du CSV',
        header: 'Header CSV',
        download: 'Télécharger le CSV',
        field: 'Champ BoondManager',
        line: 'Ligne {{row}}',
        parsingInProgress: 'Analyse du document en cours',
        notimported: {
          label: "L'erreur suivante s'est produite durant le dernier import de vos données :",
          label_plural: 'Les {{count, total}} erreurs suivantes se sont produites durant le dernier import de vos données :',
          global: 'Globale : {{message}}',
          rowWithAttributes: 'Ligne {{row}} - {{attributes}} : {{message}}',
          rowWithoutAttributes: 'Ligne {{row}} : {{message}}'
        },
        previewParams: {
          showCSV: 'afficher le CSV qui sera envoyé',
          hideUnmappedColumns: 'masquer les colonnes non utilisées',
          startIndex: 'ligne début',
          endIndex: 'ligne fin'
        },
        stepOne: {
          title: 'Configurer votre importation',
          file: 'Fichier CSV contenant vos données',
          zipUrl: {
            label: 'Url du fichier ZIP à utiliser pour importer vos CVs',
            help: 'Le fichier doit être au format ZIP et accessible depuis une URL publique'
          },
          typeOf: {
            label: 'Type de données à importer',
            options: [{
              id: 'candidates',
              value: i18n.t('config:globaldata.setting.defaultPositioningSearchModule.options.candidates')
            }, {
              id: 'resources',
              value: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources')
            }, {
              id: 'contacts',
              value: i18n.t('config:importdata.stepOne.typeOf.options.contacts')
            }, {
              id: 'opportunities',
              value: i18n.t('config:importdata.stepOne.typeOf.options.opportunities')
            }, {
              id: 'actions',
              value: i18n.t('components:bmSearchFilters.action_plural')
            }]
          }
        },
        stepTwo: {
          title: 'Qualifier votre fichier de données à importer',
          delimiter: 'Délimiteur des données de votre fichier CSV',
          newLine: 'Caractère de fin de ligne',
          escapeChar: "Caractère d'échappement",
          quoteChar: 'Délimiteur de champ',
          encoding: 'Encodage',
          dateFormat: 'Format de date'
        },
        stepThree: {
          title: 'Traitement des données',
          importInProgress: 'Traitement en cours. Vous pouvez reprendre votre navigation, vous recevrez une notification à la fin du traitement.',
          importDone: 'Import terminé.',
          relaunchImport: 'Relancer un import'
        },
        fields: {
          id: 'Référence interne',
          year: 'Année',
          civility: 'Civilité',
          firstName: 'Prénom',
          lastName: 'Nom',
          state: {
            candidate: 'Étape',
            resource: 'État',
            opportunity: 'État'
          },
          typeOf: {
            candidate: 'Type',
            resource: 'Type',
            opportunity: 'Type',
            action: 'Type'
          },
          dependsOn: {
            id: 'Fiche parente'
          },
          reference: {
            resource: 'Matricule'
          },
          criteria: 'Critères requis',
          department: 'Service',
          description: 'Description',
          duration: 'Durée',
          estimatesExcludingTax: 'Budget envisagé',
          function: 'Fonction',
          globalEvaluation: 'Évaluation globale',
          text: 'Texte',
          title: 'Titre',
          dateOfBirth: 'Date de naissance',
          placeOfBirth: 'Lieu de naissance',
          availability: 'Disponibilité',
          healthCareNumber: 'Numéro de sécurité sociale',
          actualSalary: 'Salaire actuel',
          desiredSalary: {
            min: 'Salaire souhaité - Fourchette basse',
            max: 'Salaire souhaité - Fourchette haute'
          },
          actualAverageDailyCost: 'Tarif journalier HT actuel (si externe)',
          desiredAverageDailyCost: {
            min: 'Tarif journalier HT souhaité - Fourchette basse (si externe)',
            max: 'Tarif journalier HT souhaité - Fourchette haute (si externe)'
          },
          averageDailyPriceExcludingTax: 'TJM (HT)',
          resource: {
            id: 'Ressource - Id'
          },
          period: 'Période',
          company: {
            id: 'Société - Id',
            name: 'Société - Nom',
            state: 'Société - État',
            expertiseArea: "Société - Secteur d'activité",
            informationComments: 'Société - Informations',
            fax: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.fax.name')),
            address: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.address.name')),
            postcode: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.postcode.name')),
            town: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.town.name')),
            country: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.country.name')),
            number: 'Société - Numéro fournisseur',
            registrationNumber: 'Société - Siret',
            website: 'Société - Site web',
            phone1: 'Société - Télephone',
            staff: 'Société - Effectif',
            origin: {
              typeOf: 'Société - Provenance',
              detail: 'Société - Provenance - Précisez'
            },
            mainManager: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.mainManager.name')),
            agency: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.agency.name')),
            pole: "Soci\xE9t\xE9 - ".concat(i18n.t('models:company.attributes.pole.name'))
          },
          contact: {
            id: 'Contact CRM',
            civility: "Contact - ".concat(i18n.t('models:contact.attributes.civility.abbr')),
            state: 'Contact - État',
            firstName: "Contact - ".concat(i18n.t('models:contact.attributes.firstName.name')),
            lastName: "Contact - ".concat(i18n.t('models:contact.attributes.lastName.name')),
            function: "Contact - ".concat(i18n.t('models:contact.attributes.function.name')),
            department: "Contact - ".concat(i18n.t('models:contact.attributes.department.name')),
            email1: "Contact - ".concat(i18n.t('importdata.fields.email1')),
            email2: "Contact - ".concat(i18n.t('importdata.fields.email2')),
            email3: "Contact - ".concat(i18n.t('importdata.fields.email3')),
            phone1: "Contact - ".concat(i18n.t('importdata.fields.phone1')),
            phone2: "Contact - ".concat(i18n.t('importdata.fields.phone2')),
            address: "Contact - ".concat(i18n.t('models:contact.attributes.address.name')),
            postcode: "Contact - ".concat(i18n.t('models:contact.attributes.postcode.name')),
            town: "Contact - ".concat(i18n.t('models:contact.attributes.town.name')),
            country: "Contact - ".concat(i18n.t('models:contact.attributes.country.name')),
            activityAreas: "Contact - ".concat(i18n.t('models:contact.attributes.activityAreas.name')),
            informationComments: 'Contact - Périmètre technique',
            origin: {
              typeOf: 'Contact - Provenance',
              detail: 'Contact - Provenance - Précisez'
            },
            action: {
              startDate: "Contact - ".concat(i18n.t('importdata.fields.action.startDate')),
              text: "Contact -  ".concat(i18n.t('adminimportdata:view.fields.action.text'))
            },
            mainManager: "Contact -  ".concat(i18n.t('models:contact.attributes.mainManager.name')),
            agency: "Contact -  ".concat(i18n.t('models:contact.attributes.agency.name')),
            pole: "Contact -  ".concat(i18n.t('models:contact.attributes.pole.name'))
          },
          contract: {
            typeOf: 'Contrat - Type',
            startDate: "Contrat - Date d'embauche",
            endDate: 'Contrat - Date de départ',
            monthlySalary: 'Contrat - Salaire mensuel brut',
            numberOfWorkingDays: 'Contrat - Nombre de jours ouvrés annuel',
            chargeFactor: 'Contrat - Coefficient de charge',
            dailyExpenses: 'Contrat - Frais net journalier',
            monthlyExpenses: 'Contrat - Frais net mensuel',
            employeeType: 'Contrat - Catégorie',
            classification: 'Contrat - Classification'
          },
          email1: 'Email 1',
          email2: 'Email 2',
          email3: 'Email 3',
          phone1: 'Téléphone 1',
          phone2: 'Téléphone 2',
          phone3: 'Téléphone 3',
          fax: 'Fax',
          nationality: 'Nationalité',
          address: 'Adresse',
          postcode: 'Code postal',
          town: 'Ville',
          country: 'Pays',
          experience: 'Expérience',
          training: 'Formation',
          skills: 'Compétences',
          tools: 'Outils',
          diplomas: 'Diplômes',
          languages: 'Langues',
          activityAreas: 'Domaines',
          expertiseAreas: 'Secteurs',
          mobilityAreas: 'Mobilité',
          desiredContract: 'Contrat souhaité',
          source: {
            candidate: {
              typeOf: 'Provenance - Type',
              detail: 'Provenance - Détail'
            },
            contact: {
              typeOf: 'Provenance - Type',
              detail: 'Provenance - Détail'
            },
            company: {
              typeOf: 'Provenance - Type',
              detail: 'Provenance - Détail'
            }
          },
          action: {
            startDate: 'Note - Date',
            text: 'Note - Texte'
          },
          informationComments: 'Commentaires',
          creationDate: 'Date de création',
          socialNetworks: {
            label: 'Réseaux sociaux',
            option: [{
              id: 'viadeo',
              value: i18n.t('config:importdata.fields.socialNetworks.option.viadeo')
            }, {
              id: 'linkedin',
              value: i18n.t('config:importdata.fields.socialNetworks.option.linkedin')
            }, {
              id: 'facebook',
              value: i18n.t('config:importdata.fields.socialNetworks.option.facebook')
            }, {
              id: 'twitter',
              value: i18n.t('config:importdata.fields.socialNetworks.option.twitter')
            }]
          },
          resume1: 'CV 1',
          resume2: 'CV 2',
          resume3: 'CV 3',
          resume4: 'CV 4',
          resume5: 'CV 5',
          startDate: 'Date de démarrage',
          turnoverEstimatedExcludingTax: 'CA envisagé',
          mainManager: 'Responsable manager',
          hrManager: 'Responsable RH',
          agency: 'Agence',
          pole: 'Pôle',
          weighting: 'Pondération'
        }
      },
      bankingConnections: {
        state: {
          options: i18n.t('models:bankingconnection.attributes.state.values', {
            returnObjects: true
          })
        }
      },
      bankingTransactions: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:bankingTransactions.filters.periods.created')
          }],
          keywordsType: [{
            id: 'description',
            value: i18n.t('config:bankingTransactions.filters.keywordsType.description')
          }, {
            id: 'amount',
            value: i18n.t('config:bankingTransactions.filters.keywordsType.amount')
          }]
        },
        state: {
          options: i18n.t('models:bankingtransaction.attributes.state.values', {
            returnObjects: true
          })
        }
      },
      billableItems: {
        filters: {
          periods: [{
            id: 'billableItem',
            value: i18n.t('config:billableItems.filters.periods.billableItem')
          }]
        },
        state: {
          options: i18n.t('models:billableitem.attributes.state.values', {
            returnObjects: true
          })
        },
        typeOf: {
          options: i18n.t('models:billableitem.attributes.typeOf.values', {
            returnObjects: true
          })
        }
      },
      billingDeliveries: {
        filters: {
          periods: [{
            id: 'inProgress',
            value: i18n.t('config:billingDeliveries.filters.periods.inProgress')
          }]
        }
      },
      billingProjects: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:billingProjects.filters.periods.created')
          }, {
            id: 'updated',
            value: i18n.t('extractbi:fields.request.updateDate')
          }]
        }
      },
      candidates: {
        filters: {
          keywordsType: [{
            id: 'resume',
            value: i18n.t('config:candidates.filters.keywordsType.resume')
          }, {
            id: 'td',
            value: i18n.t('config:candidates.filters.keywordsType.td')
          }, {
            id: 'lastName',
            value: i18n.t('config:candidates.filters.keywordsType.lastName')
          }, {
            id: 'firstName',
            value: i18n.t('config:candidates.filters.keywordsType.firstName')
          }, {
            id: 'fullName',
            value: i18n.t('config:candidates.filters.keywordsType.fullName')
          }, {
            id: 'emails',
            value: i18n.t('config:candidates.filters.keywordsType.emails')
          }, {
            id: 'phones',
            value: i18n.t('config:candidates.filters.keywordsType.phones')
          }, {
            id: 'title',
            value: i18n.t('config:candidates.filters.keywordsType.title')
          }, {
            id: 'titleSkills',
            value: i18n.t('config:candidates.filters.keywordsType.titleSkills')
          }],
          periods: [{
            id: 'created',
            value: i18n.t('config:candidates.filters.periods.created')
          }, {
            id: 'updated',
            value: i18n.t('config:candidates.filters.periods.updated')
          }, {
            id: 'available',
            value: i18n.t('config:candidates.filters.periods.available')
          }, {
            id: 'noAction',
            value: i18n.t('config:candidates.filters.periods.noAction')
          }, {
            id: 'withActions',
            value: i18n.t('config:candidates.filters.periods.withActions')
          }, {
            id: 'withoutActions',
            value: i18n.t('config:candidates.filters.periods.withoutActions')
          }]
        }
      },
      common: {
        projectMode: [{
          id: 1,
          value: i18n.t('config:common.projectMode.1')
        }, {
          id: 2,
          value: i18n.t('config:common.projectMode.2')
        }, {
          id: 3,
          value: i18n.t('config:common.projectMode.3')
        }, {
          id: 4,
          value: i18n.t('config:common.projectMode.4')
        }],
        deliveryScenario: [{
          id: 1,
          value: i18n.t('config:common.deliveryScenario.newProject')
        }, {
          id: 2,
          value: i18n.t('config:common.deliveryScenario.existingProject')
        }, {
          id: 3,
          value: i18n.t('config:common.deliveryScenario.renewal')
        }],
        activityType: {
          options: [{
            id: 'day',
            value: i18n.t('extractbi:fields.request.frequency.day')
          }, {
            id: 'workUnit',
            value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.workUnitRate')
          }]
        },
        asapDate: [{
          id: true,
          value: i18n.t('common.asapDate.0.value')
        }, {
          id: false,
          value: i18n.t('houraccounts:table.date')
        }],
        deliveryState: [{
          id: 'production',
          value: i18n.t('advmodprojects:view.orders.production')
        }, {
          id: 'internal',
          value: i18n.t('wextractpayroll:internalTimes')
        }, {
          id: 'absence',
          value: i18n.t('houraccounts:table.absence.title')
        }],
        kanban: {
          viewModes: [{
            id: 'list',
            value: i18n.t('common:kanban.viewModes.list')
          }, {
            id: 'kanban',
            value: i18n.t('common:kanban.viewModes.kanban')
          }]
        },
        languages: [{
          id: 'fr',
          value: i18n.t('config:common.languages.fr')
        }, {
          id: 'en',
          value: i18n.t('config:common.languages.en')
        }],
        months: [{
          id: 'january',
          value: i18n.t('common:dateAndTime.months.january')
        }, {
          id: 'february',
          value: i18n.t('common:dateAndTime.months.february')
        }, {
          id: 'march',
          value: i18n.t('common:dateAndTime.months.march')
        }, {
          id: 'april',
          value: i18n.t('common:dateAndTime.months.april')
        }, {
          id: 'may',
          value: i18n.t('common:dateAndTime.months.may')
        }, {
          id: 'june',
          value: i18n.t('common:dateAndTime.months.june')
        }, {
          id: 'july',
          value: i18n.t('common:dateAndTime.months.july')
        }, {
          id: 'august',
          value: i18n.t('common:dateAndTime.months.august')
        }, {
          id: 'september',
          value: i18n.t('common:dateAndTime.months.september')
        }, {
          id: 'october',
          value: i18n.t('common:dateAndTime.months.october')
        }, {
          id: 'november',
          value: i18n.t('common:dateAndTime.months.november')
        }, {
          id: 'december',
          value: i18n.t('common:dateAndTime.months.december')
        }],
        monthsPeriodsInterval: [{
          id: 'JanuaryToDecember',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.januaryToDecember')
        }, {
          id: 'FebruaryToJanuary',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.februaryToJanuary')
        }, {
          id: 'MarchToFebruary',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.marchToFebruary')
        }, {
          id: 'AprilToMarch',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.aprilToMarch')
        }, {
          id: 'MayToApril',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.mayToApril')
        }, {
          id: 'JuneToMay',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.juneToMay')
        }, {
          id: 'JulyToJune',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.julyToJune')
        }, {
          id: 'AugustToJuly',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.augustToJuly')
        }, {
          id: 'SeptemberToAugust',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.septemberToAugust')
        }, {
          id: 'OctoberToSeptember',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.octoberToSeptember')
        }, {
          id: 'NovemberToOctober',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.novemberToOctober')
        }, {
          id: 'DecemberToNovember',
          value: i18n.t('common:dateAndTime.monthsPeriodsInterval.decemberToNovember')
        }],
        productionPlansState: [{
          id: 'signedDelivery',
          value: i18n.t('config:common.productionPlansState.signedDelivery')
        }, {
          id: 'forecastDelivery',
          value: i18n.t('config:common.productionPlansState.forecastDelivery')
        }, {
          id: 'internal',
          value: i18n.t('wextractpayroll:internalTimes')
        }, {
          id: 'absence',
          value: i18n.t('houraccounts:table.absence.title')
        }, {
          id: 'positioning',
          value: i18n.t('models:positioning.name')
        }, {
          id: 'contract',
          value: i18n.t('config:common.productionPlansState.contract')
        }],
        validationState: [{
          id: 'pendingCreation',
          value: i18n.t('config:common.validationState.pendingCreation')
        }, {
          id: 'savedAndNoValidation',
          value: i18n.t('config:common.validationState.savedAndNoValidation')
        }, {
          id: 'waitingForValidation',
          value: i18n.t('dataclosing:view.tabs.expensesreports.activityExpenses.state.waitingForValidation')
        }, {
          id: 'validated',
          value: i18n.t('config:common.validationState.validated')
        }, {
          id: 'rejected',
          value: i18n.t('config:common.validationState.rejected')
        }]
      },
      companies: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:contacts.filters.periods.created')
          }, {
            id: 'updated',
            value: i18n.t('config:contacts.filters.periods.updated')
          }, {
            id: 'noAction',
            value: i18n.t('config:companies.filters.periods.noAction')
          }, {
            id: 'withActions',
            value: i18n.t('config:companies.filters.periods.withActions')
          }, {
            id: 'withoutActions',
            value: i18n.t('config:companies.filters.periods.withoutActions')
          }],
          keywordsType: [{
            id: 'name',
            value: i18n.t('config:companies.filters.keywordsType.name')
          }, {
            id: 'phones',
            value: i18n.t('config:candidates.filters.keywordsType.phones')
          }]
        }
      },
      component: {
        bmFieldAvailability: {
          types: {
            candidate: [{
              id: 'date',
              value: i18n.t('config:component.bmFieldAvailability.types.candidate.date')
            }],
            resource: [{
              id: 'immediate',
              value: i18n.t('common.asapDate.0.value')
            }, {
              id: 'date',
              value: i18n.t('config:component.bmFieldAvailability.types.candidate.date')
            }]
          }
        },
        bmFieldTransfer: {
          actionValue: [{
            id: 'force',
            value: i18n.t('config:component.bmFieldTransfer.actionValue.force')
          }, {
            id: 'select',
            value: i18n.t('config:component.bmFieldTransfer.actionValue.select')
          }]
        },
        bmModalDeliveryOrder: {
          actions: [{
            footer: i18n.t('component.bmModalDeliveryOrder.actions.0.footer'),
            id: 'download',
            value: i18n.t('common:actions.download')
          }, {
            footer: i18n.t('config:component.bmModalDeliveryOrder.actions.footer'),
            id: 'send',
            value: i18n.t('config:component.bmModalDeliveryOrder.actions.send')
          }]
        },
        bmModalForm: {
          candidate: {
            convertToExistingResource: {
              updateFieldsOptions: [{
                id: 'tabInformation',
                value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.tabInformation'),
                option: [{
                  id: 'title',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.0.option.title')
                }, {
                  id: 'emails',
                  value: i18n.t('config:candidates.filters.keywordsType.emails')
                }, {
                  id: 'phones',
                  value: i18n.t('config:candidates.filters.keywordsType.phones')
                }, {
                  id: 'address',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.0.option.address')
                }, {
                  id: 'resumes',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.0.option.resumes')
                }]
              }, {
                id: 'tabAdministrative',
                value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.tabAdministrative'),
                option: [{
                  id: 'files',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.1.option.files')
                }, {
                  id: 'situation',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.1.option.situation')
                }]
              }, {
                id: 'tabTechnicalData',
                value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.tabTechnicalData'),
                option: [{
                  id: 'skills',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.skills')
                }, {
                  id: 'experience',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.experience')
                }, {
                  id: 'training',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.training')
                }, {
                  id: 'diplomas',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.diplomas')
                }, {
                  id: 'languages',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.languages')
                }, {
                  id: 'tools',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.tools')
                }, {
                  id: 'activityAreas',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.activityAreas')
                }, {
                  id: 'expertiseAreas',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.expertiseAreas')
                }, {
                  id: 'references',
                  value: i18n.t('config:component.bmModalForm.candidate.convertToExistingResource.updateFieldsOptions.2.option.references')
                }]
              }]
            }
          }
        },
        bmRightsFilters: {
          modules: {
            main: [{
              id: 'candidates',
              value: i18n.t('components:bmRightsFilters.modules.main.0.value'),
              icon: 'bmi-candidate',
              moduleTitle: i18n.t('components:bmRightsFilters.modules.main.0.value'),
              moduleIcon: 'bmi-candidate',
              subModules: [{
                id: 'candidatesContracts',
                value: i18n.t('components:bmRightsFilters.modules.main.0.subModules.0.value'),
                icon: 'bmi-contract'
              }]
            }, {
              id: 'resources',
              value: i18n.t('components:bmRightsFilters.modules.main.1.value'),
              icon: 'bmi-resource',
              moduleTitle: i18n.t('components:bmRightsFilters.modules.main.1.value'),
              moduleIcon: 'bmi-resource',
              subModules: [{
                id: 'contracts',
                value: i18n.t('components:bmRightsFilters.modules.main.0.subModules.0.value'),
                icon: 'bmi-contract'
              }, {
                id: 'advantages',
                value: i18n.t('components:bmRightsFilters.modules.main.1.subModules.1.value'),
                icon: 'bmi-advantage'
              }, {
                id: 'followedDocuments',
                value: i18n.t('components:bmRightsFilters.modules.main.1.subModules.2.value'),
                icon: 'bmi-followeddocument'
              }, {
                id: 'inactivities',
                value: i18n.t('components:bmRightsFilters.modules.main.1.subModules.3.value'),
                icon: 'bmi-inactivity'
              }]
            }, {
              id: 'companies',
              value: i18n.t('components:bmRightsFilters.modules.main.2.value'),
              icon: 'bmi-company',
              moduleTitle: i18n.t('components:bmRightsFilters.modules.main.2.moduleTitle'),
              moduleIcon: 'bmi-crm',
              subModules: [{
                id: 'contacts',
                value: i18n.t('components:bmRightsFilters.modules.main.2.subModules.0.value'),
                icon: 'bmi-contact'
              }]
            }, {
              id: 'opportunities',
              value: i18n.t('components:bmRightsFilters.modules.main.3.value'),
              icon: 'bmi-opportunity',
              moduleTitle: i18n.t('components:bmRightsFilters.modules.main.3.value'),
              subModules: [{
                id: 'positionings',
                value: i18n.t('components:bmRightsFilters.modules.main.3.subModules.0.value'),
                icon: 'bmi-positioning'
              }]
            }, {
              id: 'projects',
              value: i18n.t('components:bmRightsFilters.modules.main.4.value'),
              icon: 'bmi-project',
              subModules: [{
                id: 'deliveries',
                value: i18n.t('components:bmRightsFilters.modules.main.4.subModules.0.value'),
                icon: 'bmi-delivery'
              }, {
                id: 'groupments',
                value: i18n.t('components:bmRightsFilters.modules.main.4.subModules.1.value'),
                icon: 'bmi-groupment'
              }]
            }, {
              id: 'timesReports',
              value: i18n.t('components:bmRightsFilters.modules.main.5.value'),
              icon: 'bmi-timesreport',
              moduleTitle: i18n.t('components:bmRightsFilters.modules.main.5.moduleTitle'),
              moduleIcon: 'bmi-activity',
              subModules: [{
                id: 'expensesReports',
                value: i18n.t('components:bmRightsFilters.modules.main.5.subModules.0.value'),
                icon: 'bmi-expensesreport'
              }, {
                id: 'absencesReports',
                value: i18n.t('components:bmRightsFilters.modules.main.5.subModules.1.value'),
                icon: 'bmi-absencesreport'
              }, {
                id: 'validations',
                value: i18n.t('components:bmRightsFilters.modules.main.5.subModules.2.value'),
                icon: 'bmi-validation'
              }]
            }, {
              id: 'invoices',
              value: i18n.t('components:bmRightsFilters.modules.main.6.value'),
              icon: 'bmi-invoice',
              moduleTitle: i18n.t('components:bmRightsFilters.modules.main.6.moduleTitle'),
              moduleIcon: 'bmi-billing',
              subModules: [{
                id: 'orders',
                value: i18n.t('components:bmRightsFilters.modules.main.6.subModules.0.value'),
                icon: 'bmi-order'
              }, {
                id: 'billableItems',
                value: i18n.t('components:bmRightsFilters.modules.main.6.subModules.1.value'),
                icon: 'bmi-billable-items'
              }]
            }, {
              id: 'purchases',
              value: i18n.t('components:bmRightsFilters.modules.main.7.value'),
              icon: 'bmi-purchase',
              subModules: [{
                id: 'providerInvoices',
                value: i18n.t('components:bmRightsFilters.modules.main.7.subModules.1.value'),
                icon: 'bmi-lines'
              }, {
                id: 'payments',
                value: i18n.t('components:bmRightsFilters.modules.main.7.subModules.0.value'),
                icon: 'bmi-payment'
              }]
            }, {
              id: 'products',
              value: i18n.t('components:bmRightsFilters.modules.main.8.value'),
              icon: 'bmi-product'
            }, {
              id: _bm.MODULE_BANKING_TRANSACTIONS,
              value: i18n.t('components:bmRightsFilters.modules.main.9.value'),
              icon: 'bmi-transactions'
            }],
            mainIntranet: [{
              id: 'myTimesReports',
              value: i18n.t('timesreports:title_employee'),
              icon: 'bmi-timesreport'
            }, {
              id: 'myExpensesReports',
              value: i18n.t('expensesreports:title_employee'),
              icon: 'bmi-expensesreport'
            }, {
              id: 'myAbsencesReports',
              value: i18n.t('absencesreports:title_employee'),
              icon: 'bmi-absencesreport'
            }, {
              id: 'myProviderInvoices',
              value: i18n.t('providerinvoices:title_employee'),
              icon: 'bmi-lines'
            }, {
              id: 'myProjects',
              value: i18n.t('projects:title_employee'),
              icon: 'bmi-project'
            }, {
              id: 'myForms',
              value: i18n.t('forms:title_employee'),
              icon: 'bmi-form'
            }],
            planningAndReporting: [{
              id: 'planning',
              value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.0.value'),
              children: [{
                id: 'reportingProductionPlans',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.0.children.0.value'),
                icon: 'bmi-planningproductionplan'
              }, {
                id: 'planningAbsences',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.0.children.1.value'),
                icon: 'bmi-planningabsence'
              }]
            }, {
              id: 'reporting',
              value: i18n.t('config:settings.intranet.menu.manager.reporting'),
              children: [{
                id: 'reportingSynthesis',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.1.children.0.value'),
                icon: 'bmi-reporting'
              }, {
                id: 'reportingProjects',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.1.children.1.value'),
                icon: 'bmi-reporting'
              }, {
                id: 'reportingResources',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.1.children.2.value'),
                icon: 'bmi-reporting'
              }, {
                id: 'reportingCompanies',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.1.children.3.value'),
                icon: 'bmi-reporting'
              }]
            }, {
              id: 'advancedReporting',
              value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.2.value'),
              children: [{
                id: 'advancedReportingResources',
                value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.2.children.0.value'),
                icon: 'bmi-reporting'
              }]
            }],
            miscellaneous: [{
              id: 'actions',
              value: i18n.t('actions:title'),
              icon: 'bmi-action'
            }, {
              id: 'forms',
              value: i18n.t('forms:title'),
              icon: 'bmi-form'
            }, {
              id: 'flags',
              value: i18n.t('flags:title'),
              icon: 'bmi-flag'
            }, {
              id: 'sharings',
              value: i18n.t('admintemplates:view.sharings.title'),
              icon: 'bmi-share',
              subModules: [{
                id: 'threads',
                value: i18n.t('common:thread.featureTitle'),
                icon: 'bmi-share-threads'
              }, {
                id: 'sharedEntities',
                value: i18n.t('admintemplates:view.sharedentities.title'),
                icon: 'bmi-ellipsis-h-light'
              }]
            }, {
              id: 'notifications',
              value: i18n.t('notifications.title'),
              icon: 'bmi-notifications'
            }, {
              id: 'downloadCenter',
              value: i18n.t('downloadcenter:title'),
              icon: 'bmi-downloadcenter'
            }, {
              id: 'logs',
              value: i18n.t('logs:title'),
              icon: 'bmi-log'
            }, {
              id: 'subscription',
              value: i18n.t('subscription:title'),
              icon: 'bmi-subscription'
            }],
            administration: [{
              id: 'accounts',
              value: i18n.t('components:bmRightsFilters.modules.administration.accounts'),
              icon: 'bmi-account'
            }, {
              id: 'roles',
              value: i18n.t('components:bmRightsFilters.modules.administration.roles'),
              icon: 'bmi-role'
            }, {
              id: 'agencies',
              value: i18n.t('components:bmRightsFilters.modules.administration.agencies'),
              icon: 'bmi-agency'
            }, {
              id: 'poles',
              value: i18n.t('components:bmRightsFilters.modules.administration.poles'),
              icon: 'bmi-pole'
            }, {
              id: 'businessUnits',
              value: i18n.t('components:bmRightsFilters.modules.administration.businessUnits'),
              icon: 'bmi-businessunit'
            }, {
              id: 'globalData',
              value: i18n.t('components:bmRightsFilters.modules.administration.globalData'),
              icon: 'bmi-globaldata'
            }, {
              id: 'templatesActions',
              value: i18n.t('components:bmRightsFilters.modules.administration.templatesActions'),
              icon: 'bmi-templates'
            }, {
              id: 'templatesTodoLists',
              value: i18n.t('components:bmRightsFilters.modules.administration.templatesTodoLists'),
              icon: 'bmi-templates'
            }, {
              id: 'templatesForms',
              value: i18n.t('components:bmRightsFilters.modules.administration.templatesForms'),
              icon: 'bmi-form'
            }, {
              id: 'templatesSavedSearches',
              value: i18n.t('components:bmRightsFilters.modules.administration.templatesSavedSearches'),
              icon: 'bmi-templates'
            }, {
              id: 'marketplace',
              value: i18n.t('components:bmRightsFilters.modules.administration.marketplace'),
              icon: 'bmi-app'
            }, {
              id: 'alerts',
              value: i18n.t('components:bmRightsFilters.modules.administration.alerts'),
              icon: 'bmi-alerts'
            }, {
              id: 'importData',
              value: i18n.t('components:bmRightsFilters.modules.administration.importData'),
              icon: 'bmi-import'
            }, {
              id: 'development',
              value: i18n.t('components:bmRightsFilters.modules.administration.development'),
              icon: 'bmi-developer'
            }, {
              id: 'webHooks',
              value: i18n.t('components:bmRightsFilters.modules.administration.webHooks'),
              icon: 'bmi-apps'
            }, {
              id: 'customers',
              value: i18n.t('components:bmRightsFilters.modules.administration.customers'),
              icon: 'bmi-customer'
            }, {
              id: 'architecture',
              value: i18n.t('components:bmRightsFilters.modules.administration.architecture'),
              icon: 'bmi-database'
            }, {
              id: 'featureFlag',
              value: i18n.t('components:bmRightsFilters.modules.administration.featureFlag'),
              icon: 'bmi-flask-gear'
            }, {
              id: 'templatesRoles',
              value: i18n.t('components:bmRightsFilters.modules.administration.templatesRoles'),
              icon: 'bmi-role'
            }, {
              id: 'bankingConnections',
              value: i18n.t('components:bmRightsFilters.modules.administration.bankingConnections'),
              icon: 'bmi-bank'
            }],
            apps: {
              label: 'Fonctionnalités avancées des apps',
              calendar: 'App permettant de synchroniser votre calendrier avec les actions',
              mail: 'App permettant de rechercher les emails dans votre messagerie',
              viewer: 'App permettant de prévisualiser tous les documents',
              emailing: "App permettant d'envoyer des emails de masse",
              isTemplate: "Vous ne pouvez configurer que les apps qui sont également installées sur votre interface.<br><br>Si le client qui utilise ce modèle n'a pas installé l'app, il n'y aura donc pas accès !",
              add: 'Installer de nouvelles apps'
            }
          }
        },
        bmStateManager: {
          emailingType: {
            options: [{
              id: 'pushes',
              value: i18n.t('components:bmStateManager.emailingType.options.pushes')
            }, {
              id: 'messages',
              value: i18n.t('wemailing:settings.messages.label')
            }, {
              id: 'invoices',
              value: i18n.t('dataclosing:view.invoices.title')
            }]
          }
        },
        bootbox: {
          export: {
            documentsItems: [{
              id: 'formattedFilesAndDocuments',
              value: i18n.t('components:modals.export.documentsItems.formattedFilesAndDocuments')
            }, {
              id: 'documents',
              value: i18n.t('components:modals.export.documentsItems.documents')
            }, {
              id: 'formattedFiles',
              value: i18n.t('components:modals.export.documentsItems.formattedFiles')
            }, {
              id: 'internalAndCertifiedDocuments',
              value: i18n.t('components:modals.export.documentsItems.internalAndCertifiedDocuments')
            }, {
              id: 'certifiedDocuments',
              value: i18n.t('components:modals.export.documentsItems.certifiedDocuments')
            }]
          },
          extract: {
            timereportType: [{
              id: 'notDetailedInDays',
              value: i18n.t('components:modals.extract.timereportType.notDetailedInDays')
            }, {
              id: 'detailedInDays',
              value: i18n.t('components:modals.extract.timereportType.detailedInDays')
            }, {
              id: 'notDetailedInWorkUnitRate',
              value: i18n.t('components:modals.extract.timereportType.notDetailedInWorkUnitRate')
            }, {
              id: 'detailedInWorkUnitRate',
              value: i18n.t('components:modals.extract.timereportType.detailedInWorkUnitRate')
            }],
            customerType: [{
              id: 'accounts',
              value: i18n.t('components:modals.extract.customerType.accounts')
            }, {
              id: 'activeEmails',
              value: i18n.t('components:modals.extract.customerType.activeEmails')
            }, {
              id: 'customers',
              value: i18n.t('components:modals.extract.customerType.customers')
            }],
            type: [{
              id: 'detailed',
              value: i18n.t('components:modals.extract.type.detailed')
            }, {
              id: 'notDetailed',
              value: i18n.t('components:modals.extract.type.notDetailed')
            }],
            extractPayroll: [{
              id: 'extractInDays',
              value: i18n.t('components:modals.extract.extractPayroll.extractInDays')
            }, {
              id: 'extractInUnitsOfWork',
              value: i18n.t('components:modals.extract.extractPayroll.extractInUnitsOfWork')
            }]
          },
          rejectListItems: [{
            id: 'correctionForPreviousValidator',
            value: i18n.t('components:modals.rejectListItems.correctionForPreviousValidator')
          }, {
            id: 'correctionForAllValidators',
            value: i18n.t('components:modals.rejectListItems.correctionForAllValidators')
          }, {
            id: 'definitiveRefusal',
            value: i18n.t('components:modals.rejectListItems.definitiveRefusal')
          }]
        },
        filterPeriod: {
          periodDynamic: {
            today: {
              id: 'today',
              value: i18n.t('common:dateAndTime.today')
            },
            thisWeek: {
              id: 'thisWeek',
              value: i18n.t('components:filterPeriod.thisWeek')
            },
            thisMonth: {
              id: 'thisMonth',
              value: i18n.t('components:filterPeriod.thisMonth')
            },
            thisTrimester: {
              id: 'thisTrimester',
              value: i18n.t('components:filterPeriod.thisTrimester')
            },
            thisSemester: {
              id: 'thisSemester',
              value: i18n.t('components:filterPeriod.thisSemester')
            },
            thisYear: {
              id: 'thisYear',
              value: i18n.t('components:filterPeriod.thisYear')
            },
            thisFiscalYear: {
              id: 'thisFiscalYear',
              value: i18n.t('components:filterPeriod.thisFiscalYear')
            },
            yesterday: {
              id: 'yesterday',
              value: i18n.t('components:filterPeriod.yesterday')
            },
            lastWeek: {
              id: 'lastWeek',
              value: i18n.t('components:filterPeriod.lastWeek')
            },
            lastMonth: {
              id: 'lastMonth',
              value: i18n.t('components:filterPeriod.lastMonth')
            },
            lastTrimester: {
              id: 'lastTrimester',
              value: i18n.t('components:filterPeriod.lastTrimester')
            },
            lastSemester: {
              id: 'lastSemester',
              value: i18n.t('components:filterPeriod.lastSemester')
            },
            lastYear: {
              id: 'lastYear',
              value: i18n.t('components:filterPeriod.lastYear')
            },
            lastFiscalYear: {
              id: 'lastFiscalYear',
              value: i18n.t('components:filterPeriod.lastFiscalYear')
            },
            untilToday: {
              id: 'untilToday',
              value: i18n.t('components:filterPeriod.untilToday')
            },
            tomorrow: {
              id: 'tomorrow',
              value: i18n.t('components:filterPeriod.tomorrow')
            },
            nextWeek: {
              id: 'nextWeek',
              value: i18n.t('components:filterPeriod.nextWeek')
            },
            nextMonth: {
              id: 'nextMonth',
              value: i18n.t('components:filterPeriod.nextMonth')
            },
            nextTrimester: {
              id: 'nextTrimester',
              value: i18n.t('components:filterPeriod.nextTrimester')
            },
            nextSemester: {
              id: 'nextSemester',
              value: i18n.t('components:filterPeriod.nextSemester')
            },
            nextYear: {
              id: 'nextYear',
              value: i18n.t('components:filterPeriod.nextYear')
            },
            nextFiscalYear: {
              id: 'nextFiscalYear',
              value: i18n.t('components:filterPeriod.nextFiscalYear')
            },
            lastCustomPeriod: {
              id: 'lastCustomPeriod',
              value: i18n.t('components:filterPeriod.lastCustomPeriod.label'),
              customValues: [{
                id: 'days',
                value: i18n.t('components:filterPeriod.lastCustomPeriod.days')
              }, {
                id: 'weeks',
                value: i18n.t('components:filterPeriod.lastCustomPeriod.weeks')
              }, {
                id: 'months',
                value: i18n.t('components:filterPeriod.lastCustomPeriod.months')
              }, {
                id: 'trimesters',
                value: i18n.t('components:filterPeriod.lastCustomPeriod.trimesters')
              }, {
                id: 'semesters',
                value: i18n.t('components:filterPeriod.lastCustomPeriod.semesters')
              }, {
                id: 'years',
                value: i18n.t('components:filterPeriod.lastCustomPeriod.years')
              }]
            },
            nextCustomPeriod: {
              id: 'nextCustomPeriod',
              value: i18n.t('components:filterPeriod.nextCustomPeriod.label'),
              customValues: [{
                id: 'days',
                value: i18n.t('components:filterPeriod.nextCustomPeriod.days')
              }, {
                id: 'weeks',
                value: i18n.t('components:filterPeriod.nextCustomPeriod.weeks')
              }, {
                id: 'months',
                value: i18n.t('components:filterPeriod.nextCustomPeriod.months')
              }, {
                id: 'trimesters',
                value: i18n.t('components:filterPeriod.nextCustomPeriod.trimesters')
              }, {
                id: 'semesters',
                value: i18n.t('components:filterPeriod.nextCustomPeriod.semesters')
              }, {
                id: 'years',
                value: i18n.t('components:filterPeriod.nextCustomPeriod.years')
              }]
            }
          },
          periodType: {
            indifferent: {
              id: -1,
              value: i18n.t('common:indifferent')
            }
          },
          range: {
            thisCustomPeriod: {
              id: 6,
              value: i18n.t('common.customPeriod')
            },
            thisDynamicPeriod: {
              id: 7,
              value: i18n.t('common.periodDynamic')
            },
            immediate: {
              id: 0,
              value: i18n.t('common.immediate')
            },
            thisSemester: {
              id: 4,
              value: i18n.t('components:filterPeriod.range.thisSemester')
            },
            thisMonth: {
              id: 2,
              value: i18n.t('components:filterPeriod.range.thisMonth')
            },
            thisTrimester: {
              id: 3,
              value: i18n.t('components:filterPeriod.range.thisTrimester')
            },
            thisWeek: {
              id: 1,
              value: i18n.t('components:filterPeriod.range.thisWeek')
            },
            thisYear: {
              id: 5,
              value: i18n.t('components:filterPeriod.range.thisYear')
            },
            toDefine: {
              id: -1,
              value: i18n.t('common:toDefine')
            }
          }
        }
      },
      contacts: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:contacts.filters.periods.created')
          }, {
            id: 'updated',
            value: i18n.t('config:contacts.filters.periods.updated')
          }, {
            id: 'noAction',
            value: i18n.t('config:contacts.filters.periods.noAction')
          }, {
            id: 'withActions',
            value: i18n.t('config:contacts.filters.periods.withActions')
          }, {
            id: 'withoutActions',
            value: i18n.t('config:contacts.filters.periods.withoutActions')
          }],
          keywordsType: [{
            id: 'lastName',
            value: i18n.t('config:candidates.filters.keywordsType.lastName')
          }, {
            id: 'firstName',
            value: i18n.t('config:candidates.filters.keywordsType.firstName')
          }, {
            id: 'fullName',
            value: i18n.t('config:candidates.filters.keywordsType.fullName')
          }, {
            id: 'companyFullName',
            value: i18n.t('config:contacts.filters.keywordsType.companyFullName')
          }, {
            id: 'emails',
            value: i18n.t('config:candidates.filters.keywordsType.emails')
          }, {
            id: 'phones',
            value: i18n.t('config:candidates.filters.keywordsType.phones')
          }]
        }
      },
      contract: {
        creationForm: {
          contractType: [{
            id: 'contract',
            value: i18n.t('config:contract.creationForm.contractType.contract')
          }, {
            id: 'amendment',
            value: i18n.t('config:contract.creationForm.contractType.amendment')
          }]
        }
      },
      customer: {
        pricingType: {
          options: [{
            id: 'custom',
            value: i18n.t('config:customer.pricingType.options.custom')
          }, {
            id: 'manager',
            value: i18n.t('config:customer.pricingType.options.manager')
          }, {
            id: 'account',
            value: i18n.t('config:customer.pricingType.options.account')
          }, {
            id: 'scheduleAccount',
            value: i18n.t('config:customer.pricingType.options.scheduleAccount')
          }]
        },
        dataType: {
          options: [{
            id: 'real',
            value: i18n.t('advmodprojects:view.orders.production')
          }, {
            id: 'sandbox',
            value: i18n.t('config:customer.dataType.options.sandbox')
          }]
        },
        subscriptionStates: [{
          id: 'active',
          value: i18n.t('intranetaccounts:subscriptions.0.value')
        }, {
          id: 'demonstration',
          value: i18n.t('config:customer.subscriptionStates.demonstration')
        }, {
          id: 'partner',
          value: i18n.t('config:customer.subscriptionStates.partner')
        }, {
          id: 'internal',
          value: i18n.t('config:customer.subscriptionStates.internal')
        }, {
          id: 'inactive',
          value: i18n.t('intranetaccounts:subscriptions.1.value')
        }]
      },
      customers: {
        filters: {
          keywordsType: [{
            id: 'name',
            value: i18n.t('config:customers.filters.keywordsType.name')
          }, {
            id: 'code',
            value: i18n.t('config:customers.filters.keywordsType.code')
          }, {
            id: 'sandbox',
            value: i18n.t('config:customers.filters.keywordsType.sandbox')
          }, {
            id: 'login',
            value: i18n.t('config:customers.filters.keywordsType.login')
          }]
        }
      },
      dashboard: {
        widgetsAlerts: {
          myPeriodicalsTargets: i18n.t('dashboard:view.widgetsAlerts.myPeriodicalsTargets')
        },
        gadgetTypes: {
          widget: i18n.t('dashboard:view.gadgetType.widget'),
          metric: i18n.t('dashboard:view.gadgetType.metric'),
          collection: i18n.t('dashboard:view.gadgetType.collection')
        },
        metrics: {
          modules: [{
            id: 'actions',
            value: i18n.t('dashboard:view.metrics.modules.actions')
          }, {
            id: 'candidates',
            value: i18n.t('dashboard:view.metrics.modules.candidates')
          }, {
            id: 'resources',
            value: i18n.t('dashboard:view.metrics.modules.resources')
          }, {
            id: 'companies',
            value: i18n.t('dashboard:view.metrics.modules.companies')
          }, {
            id: 'contacts',
            value: i18n.t('dashboard:view.metrics.modules.contacts')
          }, {
            id: 'opportunities',
            value: i18n.t('dashboard:view.metrics.modules.opportunities')
          }, {
            id: 'positionings',
            value: i18n.t('dashboard:view.metrics.modules.positionings')
          }, {
            id: 'projects',
            value: i18n.t('dashboard:view.metrics.modules.projects')
          }, {
            id: 'deliveries',
            value: i18n.t('dashboard:view.metrics.modules.deliveries')
          }, {
            id: 'invoices',
            value: i18n.t('dashboard:view.metrics.modules.invoices')
          }, {
            id: 'billingSchedulesBalances',
            value: i18n.t('dashboard:view.metrics.modules.billingSchedulesBalances')
          }, {
            id: 'orders',
            value: i18n.t('dashboard:view.metrics.modules.orders')
          }, {
            id: 'billingMonthlyBalances',
            value: i18n.t('dashboard:view.metrics.modules.billingMonthlyBalances')
          }, {
            id: 'purchases',
            value: i18n.t('dashboard:view.metrics.modules.purchases')
          }, {
            id: 'payments',
            value: i18n.t('dashboard:view.metrics.modules.payments')
          }]
        },
        collections: {
          modules: [{
            id: 'actions',
            value: i18n.t('dashboard:view.metrics.modules.actions')
          }, {
            id: 'candidates',
            value: i18n.t('dashboard:view.collections.modules.candidates')
          }, {
            id: 'resources',
            value: i18n.t('dashboard:view.collections.modules.resources')
          }, {
            id: 'companies',
            value: i18n.t('dashboard:view.collections.modules.companies')
          }, {
            id: 'contacts',
            value: i18n.t('dashboard:view.collections.modules.contacts')
          }, {
            id: 'opportunities',
            value: i18n.t('dashboard:view.collections.modules.opportunities')
          }, {
            id: 'positionings',
            value: i18n.t('dashboard:view.collections.modules.positionings')
          }, {
            id: 'projects',
            value: i18n.t('dashboard:view.collections.modules.projects')
          }, {
            id: 'deliveries',
            value: i18n.t('dashboard:view.collections.modules.deliveries')
          }, {
            id: 'invoices',
            value: i18n.t('dashboard:view.metrics.modules.invoices')
          }, {
            id: 'billingSchedulesBalances',
            value: i18n.t('dashboard:view.metrics.modules.billingSchedulesBalances')
          }, {
            id: 'orders',
            value: i18n.t('dashboard:view.metrics.modules.orders')
          }, {
            id: 'billingMonthlyBalances',
            value: i18n.t('dashboard:view.metrics.modules.billingMonthlyBalances')
          }, {
            id: 'purchases',
            value: i18n.t('dashboard:view.metrics.modules.purchases')
          }, {
            id: 'payments',
            value: i18n.t('dashboard:view.metrics.modules.payments')
          }]
        },
        widgets: {
          numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E']
        }
      },
      deliveries: {
        filters: {
          deliveryGroupment: [{
            id: 'onlyDelivery',
            value: i18n.t('config:deliveries.filters.deliveryGroupment.onlyDelivery')
          }, {
            id: 'deliveryAndGroupment',
            value: i18n.t('config:deliveries.filters.deliveryGroupment.deliveryAndGroupment')
          }],
          periods: [{
            id: 'running',
            value: i18n.t('config:deliveries.filters.periods.running')
          }, {
            id: 'started',
            value: i18n.t('config:deliveries.filters.periods.started')
          }, {
            id: 'stopped',
            value: i18n.t('config:deliveries.filters.periods.stopped')
          }],
          transferTypeValues: [{
            id: 'master',
            value: i18n.t('config:deliveries.filters.transferTypeValues.master')
          }, {
            id: 'slave',
            value: i18n.t('config:deliveries.filters.transferTypeValues.slave')
          }, {
            id: 'none',
            value: i18n.t('common:none_action')
          }, {
            id: 'notSlave',
            value: i18n.t('config:deliveries.filters.transferTypeValues.notSlave')
          }, {
            id: 'notMaster',
            value: i18n.t('config:deliveries.filters.transferTypeValues.notMaster')
          }]
        }
      },
      expensesreport: {
        certification: {
          states: [{
            id: 0,
            value: i18n.t('config:expensesreport.certification.states.0')
          }, {
            id: 1,
            value: i18n.t('config:expensesreport.certification.states.1')
          }, {
            id: 2,
            value: i18n.t('config:expensesreport.certification.states.2')
          }, {
            id: 3,
            value: i18n.t('config:expensesreport.certification.states.3')
          }]
        }
      },
      expensesreports: {
        validationState: [{
          id: 'savedAndNoValidation',
          value: i18n.t('config:common.validationState.savedAndNoValidation')
        }, {
          id: 'waitingForValidation',
          value: i18n.t('dataclosing:view.tabs.expensesreports.activityExpenses.state.waitingForValidation')
        }, {
          id: 'validated',
          value: i18n.t('config:expensesreports.validationState.validated')
        }, {
          id: 'rejected',
          value: i18n.t('config:expensesreports.validationState.rejected')
        }]
      },
      forms: {
        states: [{
          icon: 'bmi-check-circle bm-validate',
          id: 'validated',
          label: i18n.t('dataclosing:view.tabs.expensesreports.activityExpenses.state.validated'),
          todolistTitle: i18n.t('components:bmModalNativeFormsWorkflow.workflowModal.title')
        }, {
          icon: 'bmi-hourglass-half bm-text-secondary',
          id: 'pending',
          label: i18n.t('forms.states.1.label'),
          todolistTitle: i18n.t('components:bmModalNativeFormsWorkflow.workflowModal.title')
        }],
        templates: {
          template: {
            mailTo: {
              options: [{
                id: 'resource',
                value: i18n.t('survey:resource')
              }, {
                id: 'mainManager',
                value: i18n.t('intranetaccounts:view.mainManager')
              }, {
                id: 'hrManager',
                value: i18n.t('advmodcandidates:search.columns.values.hrManager')
              }]
            },
            profiles: [{
              id: 'resource',
              value: i18n.t('survey:resource')
            }],
            question: {
              typesOf: [{
                id: 'barometer',
                value: i18n.t('survey:evaluation')
              }, {
                id: 'singleChoice',
                value: i18n.t('forms.templates.template.question.typesOf.1.value')
              }, {
                id: 'multipleChoice',
                value: i18n.t('forms.templates.template.question.typesOf.2.value')
              }, {
                id: 'text',
                value: i18n.t('forms.templates.template.question.typesOf.3.value')
              }, {
                id: 'number',
                value: i18n.t('forms.templates.template.question.typesOf.4.value')
              }]
            }
          }
        }
      },
      groupment: {
        loadDistribution: {
          ruleOptions: [{
            id: 'proportional',
            value: i18n.t('config:groupment.loadDistribution.ruleOptions.proportional')
          }, {
            id: 'weighted',
            value: i18n.t('config:groupment.loadDistribution.ruleOptions.weighted')
          }, {
            id: 'manual',
            value: i18n.t('config:groupment.loadDistribution.ruleOptions.manual')
          }]
        }
      },
      inactivity: {
        stateTypes: [{
          id: 'internal',
          value: i18n.t('wextractpayroll:internalTimes')
        }, {
          id: 'absence',
          value: i18n.t('houraccounts:table.absence.title')
        }]
      },
      invoices: {
        exportTypes: [{
          id: 'facturx',
          value: i18n.t('components:modals.export.documentsItems.facturx')
        }, {
          id: 'cii',
          value: i18n.t('components:modals.export.documentsItems.cii')
        }, {
          id: 'documentsAndAttachments',
          value: i18n.t('components:modals.export.invoicesItems.formattedFilesAndDocuments')
        }, {
          id: 'documentsOnly',
          value: i18n.t('components:modals.export.invoicesItems.documents')
        }, {
          id: 'attachmentsOnly',
          value: i18n.t('components:modals.export.invoicesItems.formattedFiles')
        }],
        exportTypesOptions: [{
          id: 'native',
          value: i18n.t('components:modals.export.invoiceExportTypes.native')
        }, {
          id: 'facturx',
          value: i18n.t('components:modals.export.invoiceExportTypes.facturx')
        }, {
          id: 'cii',
          value: i18n.t('components:modals.export.invoiceExportTypes.cii')
        }],
        filters: {
          creditNote: [{
            id: '',
            value: i18n.t('houraccounts:filters.validated.options.0.value')
          }, {
            id: true,
            value: i18n.t('config:invoices.filters.creditNote.true')
          }, {
            id: false,
            value: i18n.t('config:invoices.filters.creditNote.false')
          }],
          periods: [{
            id: 'created',
            value: i18n.t('config:invoices.filters.periods.created')
          }, {
            id: 'expectedPayment',
            value: i18n.t('config:invoices.filters.periods.expectedPayment')
          }, {
            id: 'performedPayment',
            value: i18n.t('config:invoices.filters.periods.performedPayment')
          }, {
            id: 'period',
            value: i18n.t('config:invoices.filters.periods.period')
          }]
        }
      },
      logs: {
        action: {
          option: [{
            id: 'info',
            value: i18n.t('components:bmNotificationBar.tabs.information')
          }, {
            id: 'create',
            value: i18n.t('advmodcandidates:search.columns.values.creationDate')
          }, {
            id: 'update',
            value: i18n.t('config:logs.action.option.update')
          }, {
            id: 'delete',
            value: i18n.t('config:logs.action.option.delete')
          }, {
            id: 'extract',
            value: i18n.t('wextractpayroll:settings.extraction.label')
          }, {
            id: 'share',
            value: i18n.t('config:logs.action.option.share')
          }, {
            id: 'export',
            value: i18n.t('config:logs.action.option.export')
          }]
        },
        auth: {
          option: [{
            id: 'normal',
            value: i18n.t('config:logs.auth.option.normal')
          }, {
            id: 'basic',
            value: i18n.t('config:logs.auth.option.basic')
          }, {
            id: 'xjwtapp',
            value: i18n.t('config:logs.auth.option.xjwtapp')
          }, {
            id: 'xjwtclient',
            value: i18n.t('config:logs.auth.option.xjwtclient')
          }]
        },
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:logs.filters.periods.created')
          }]
        }
      },
      marketplace: {
        categories: {
          option: [{
            id: 'other',
            value: i18n.t('components:bmRightsFilters.modal.entity.access.others')
          }, {
            id: 'mail',
            value: i18n.t('config:app.category.option.mail')
          }, {
            id: 'calendar',
            value: i18n.t('config:app.category.option.calendar')
          }, {
            id: 'mailAndCalendar',
            value: i18n.t('config:app.category.option.mailAndCalendar')
          }, {
            id: 'certification',
            value: i18n.t('config:app.category.option.certification')
          }, {
            id: 'emailing',
            value: i18n.t('wemailing:title')
          }, {
            id: 'templates',
            value: i18n.t('config:app.category.option.templates')
          }, {
            id: 'viewer',
            value: i18n.t('config:app.category.option.viewer')
          }]
        },
        belongsTo: {
          option: [{
            id: 'myApps',
            value: i18n.t('config:marketplace.belongsTo.option.myApps')
          }, {
            id: 'otherApps',
            value: i18n.t('config:marketplace.belongsTo.option.otherApps')
          }]
        }
      },
      modules: {
        options: [{
          id: 'absencesReports',
          value: i18n.t('config:modules.options.absencesReports')
        }, {
          id: 'accounts',
          value: i18n.t('config:modules.options.accounts')
        }, {
          id: 'actions',
          value: i18n.t('config:modules.options.actions')
        }, {
          id: 'customers',
          value: i18n.t('config:modules.options.administrator.customers')
        }, {
          id: 'templatesSavedSearches',
          value: i18n.t('config:modules.options.administrator.templates.templatesSavedSearches')
        }, {
          id: 'templatesTodoLists',
          value: i18n.t('config:modules.options.administrator.templates.templatesTodoLists')
        }, {
          id: 'templatesActions',
          value: i18n.t('config:modules.options.administrator.templates.templatesActions')
        }, {
          id: 'apps.advmodcandidates',
          value: i18n.t('config:modules.options.apps.advmodcandidates')
        }, {
          id: 'apps.advmodcustomers',
          value: i18n.t('config:modules.options.apps.advmodcustomers')
        }, {
          id: 'apps.advmodprojects',
          value: i18n.t('config:modules.options.apps.advmodprojects')
        }, {
          id: 'apps.celebrations',
          value: i18n.t('config:modules.options.apps.celebrations')
        }, {
          id: 'apps.dataclosing',
          value: i18n.t('config:modules.options.apps.dataclosing')
        }, {
          id: 'apps.digitalworkplace',
          value: i18n.t('config:modules.options.apps.digitalworkplace')
        }, {
          id: 'apps.hrflow',
          value: i18n.t('config:modules.options.apps.hrflow')
        }, {
          id: 'apps.houraccounts',
          value: i18n.t('config:modules.options.apps.houraccounts')
        }, {
          id: 'apps.intranetaccounts',
          value: i18n.t('config:modules.options.apps.intranetaccounts')
        }, {
          id: 'apps.saaseditor',
          value: i18n.t('config:modules.options.apps.saaseditor')
        }, {
          id: 'apps.sepa',
          value: i18n.t('config:modules.options.apps.sepa')
        }, {
          id: 'apps.specialreporting',
          value: i18n.t('config:modules.options.apps.specialreporting')
        }, {
          id: 'apps.survey',
          value: i18n.t('config:modules.options.apps.survey')
        }, {
          id: 'apps.wadvantages',
          value: i18n.t('config:modules.options.apps.wadvantages')
        }, {
          id: 'apps.wcontracts',
          value: i18n.t('config:modules.options.apps.wcontracts')
        }, {
          id: 'apps.wdevis',
          value: i18n.t('config:modules.options.apps.wdevis')
        }, {
          id: 'apps.wexceptionnalactivity',
          value: i18n.t('config:modules.options.apps.wexceptionnalactivity')
        }, {
          id: 'apps.wextractpayroll',
          value: i18n.t('config:modules.options.apps.wextractpayroll')
        }, {
          id: 'apps.wmarkers',
          value: i18n.t('config:modules.options.apps.wmarkers')
        }, {
          id: 'apps.wplanproduction',
          value: i18n.t('config:modules.options.apps.wplanproduction')
        }, {
          id: 'apps.wpostproduction',
          value: i18n.t('config:modules.options.apps.wpostproduction')
        }, {
          id: 'apps.wsageinterface',
          value: i18n.t('config:modules.options.apps.wsageinterface')
        }, {
          id: 'apps.wtriggersabsences',
          value: i18n.t('config:modules.options.apps.wtriggersabsences')
        }, {
          id: 'candidates',
          value: i18n.t('config:modules.options.candidates')
        }, {
          id: 'contacts',
          value: i18n.t('config:modules.options.contacts')
        }, {
          id: 'billingDeliveriesPurchasesBalances',
          value: i18n.t('config:modules.options.billingDeliveriesPurchasesBalances')
        }, {
          id: 'billingMonthlyBalances',
          value: i18n.t('config:modules.options.billingMonthlyBalances')
        }, {
          id: 'billingProjectsBalances',
          value: i18n.t('config:modules.options.billingProjectsBalances')
        }, {
          id: 'billingSchedulesBalances',
          value: i18n.t('config:modules.options.billingSchedulesBalances')
        }, {
          id: 'companies',
          value: i18n.t('config:modules.options.companies')
        }, {
          id: 'deliveries',
          value: i18n.t('config:modules.options.deliveries')
        }, {
          id: 'downloadCenter',
          value: i18n.t('config:modules.options.downloadCenter')
        }, {
          id: 'expensesReports',
          value: i18n.t('config:modules.options.expensesReports')
        }, {
          id: 'flags',
          value: i18n.t('config:modules.options.flags')
        }, {
          id: 'invoices',
          value: i18n.t('config:modules.options.invoices')
        }, {
          id: 'logs',
          value: i18n.t('config:modules.options.logs')
        }, {
          id: 'opportunities',
          value: i18n.t('config:modules.options.opportunities')
        }, {
          id: 'orders',
          value: i18n.t('config:modules.options.orders')
        }, {
          id: 'payments',
          value: i18n.t('config:modules.options.payments')
        }, {
          id: 'positionings',
          value: i18n.t('config:modules.options.positionings')
        }, {
          id: 'products',
          value: i18n.t('config:modules.options.products')
        }, {
          id: 'projects',
          value: i18n.t('config:modules.options.projects')
        }, {
          id: 'providerInvoices',
          value: i18n.t('config:modules.options.providerInvoices')
        }, {
          id: 'purchases',
          value: i18n.t('config:modules.options.purchases')
        }, {
          id: 'reportingProjects',
          value: i18n.t('config:modules.options.reportingProjects')
        }, {
          id: 'reportingResources',
          value: i18n.t('config:modules.options.reportingResources')
        }, {
          id: 'reportingCompanies',
          value: i18n.t('config:modules.options.reportingCompanies')
        }, {
          id: 'reportingSynthesis',
          value: i18n.t('config:modules.options.reportingSynthesis')
        }, {
          id: 'reportingProductionPlans',
          value: i18n.t('config:modules.options.reportingProductionPlans')
        }, {
          id: 'resources',
          value: i18n.t('config:modules.options.resources')
        }, {
          id: 'timesReports',
          value: i18n.t('config:modules.options.timesReports')
        }, {
          id: 'validations',
          value: i18n.t('config:modules.options.validations')
        }]
      },
      opportunities: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:opportunities.filters.periods.created')
          }, {
            id: 'updatedPositioning',
            value: i18n.t('config:opportunities.filters.periods.updatedPositioning')
          }, {
            id: 'started',
            value: i18n.t('config:opportunities.filters.periods.started')
          }, {
            id: 'updated',
            value: i18n.t('extractbi:fields.request.updateDate')
          }, {
            id: 'closingDate',
            value: i18n.t('config:opportunities.filters.periods.closingDate')
          }, {
            id: 'noAction',
            value: i18n.t('config:opportunities.filters.periods.noAction')
          }, {
            id: 'withActions',
            value: i18n.t('config:opportunities.filters.periods.withActions')
          }, {
            id: 'withoutActions',
            value: i18n.t('config:opportunities.filters.periods.withoutActions')
          }]
        }
      },
      order: {
        information: {
          typeOfItems: [{
            id: 'monthly',
            value: i18n.t('extractbi:frequency.typeOf.options.monthly')
          }, {
            id: 'schedule',
            value: i18n.t('config:order.information.typeOfItems.schedule')
          }]
        }
      },
      orders: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:invoices.filters.periods.created')
          }, {
            id: 'period',
            value: i18n.t('config:orders.filters.periods.period')
          }]
        }
      },
      payments: {
        filters: {
          periods: [{
            id: 'createdPurchase',
            value: i18n.t('config:payments.filters.periods.createdPurchase')
          }, {
            id: 'subscription',
            value: i18n.t('config:payments.filters.periods.subscription')
          }, {
            id: 'created',
            value: i18n.t('sepa:extractSepaFile.extractDate')
          }, {
            id: 'expected',
            value: i18n.t('config:invoices.filters.periods.expectedPayment')
          }, {
            id: 'performed',
            value: i18n.t('config:invoices.filters.periods.performedPayment')
          }, {
            id: 'billing',
            value: i18n.t('config:invoices.filters.periods.period')
          }]
        }
      },
      planningabsences: {
        periodFilter: [{
          id: 'allResources',
          value: i18n.t('wsageinterface:employees.allTypes')
        }, {
          id: 'withAbsences',
          value: i18n.t('planningabsences.periodFilter.1.value')
        }, {
          id: 'withoutAbsences',
          value: i18n.t('planningabsences.periodFilter.2.value')
        }]
      },
      positionings: {
        filters: {
          periods: [{
            id: 'updated',
            value: i18n.t('extractbi:fields.request.updateDate')
          }, {
            id: 'created',
            value: i18n.t('config:positionings.filters.periods.created')
          }]
        },
        perimeterManagersTypes: [{
          id: 'opportunities',
          value: i18n.t('config:importdata.stepOne.typeOf.options.opportunities')
        }, {
          id: 'positionings',
          value: i18n.t('components:bmSearchFilters.positioning_plural')
        }, {
          id: 'profiles',
          value: i18n.t('config:positionings.perimeterManagersTypes.profiles')
        }, {
          id: 'products',
          value: i18n.t('config:modules.options.products')
        }]
      },
      projects: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:projects.filters.periods.created')
          }, {
            id: 'closed',
            value: i18n.t('config:projects.filters.periods.closed')
          }, {
            id: 'running',
            value: i18n.t('config:projects.filters.periods.running')
          }, {
            id: 'started',
            value: i18n.t('config:projects.filters.periods.started')
          }, {
            id: 'stopped',
            value: i18n.t('config:projects.filters.periods.stopped')
          }]
        }
      },
      purchases: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('config:payments.filters.periods.createdPurchase')
          }, {
            id: 'subscription',
            value: i18n.t('config:payments.filters.periods.subscription')
          }]
        }
      },
      resources: {
        filters: {
          keywordsType: [{
            id: 'resume',
            value: i18n.t('config:resources.filters.keywordsType.resume')
          }, {
            id: 'td',
            value: i18n.t('config:resources.filters.keywordsType.td')
          }, {
            id: 'lastName',
            value: i18n.t('config:resources.filters.keywordsType.lastName')
          }, {
            id: 'firstName',
            value: i18n.t('config:resources.filters.keywordsType.firstName')
          }, {
            id: 'fullName',
            value: i18n.t('config:resources.filters.keywordsType.fullName')
          }, {
            id: 'emails',
            value: i18n.t('config:resources.filters.keywordsType.emails')
          }, {
            id: 'phones',
            value: i18n.t('config:resources.filters.keywordsType.phones')
          }, {
            id: 'reference',
            value: i18n.t('config:resources.filters.keywordsType.reference')
          }, {
            id: 'title',
            value: i18n.t('config:resources.filters.keywordsType.title')
          }, {
            id: 'titleSkills',
            value: i18n.t('config:resources.filters.keywordsType.titleSkills')
          }],
          periods: [{
            id: 'working',
            value: i18n.t('config:resources.filters.periods.working')
          }, {
            id: 'absent',
            value: i18n.t('config:resources.filters.periods.absent')
          }, {
            id: 'idle',
            value: i18n.t('config:resources.filters.periods.idle')
          }, {
            id: 'hired',
            value: i18n.t('config:resources.filters.periods.hired')
          }, {
            id: 'left',
            value: i18n.t('config:resources.filters.periods.left')
          }, {
            id: 'updated',
            value: i18n.t('config:resources.filters.periods.updated')
          }, {
            id: 'employed',
            value: i18n.t('config:resources.filters.periods.employed')
          }, {
            id: 'available',
            value: i18n.t('config:resources.filters.periods.available')
          }, {
            id: 'unemployed',
            value: i18n.t('config:resources.filters.periods.unemployed')
          }, {
            id: 'noAction',
            value: i18n.t('config:resources.filters.periods.noAction')
          }, {
            id: 'withActions',
            value: i18n.t('config:resources.filters.periods.withActions')
          }, {
            id: 'withoutActions',
            value: i18n.t('config:resources.filters.periods.withoutActions')
          }]
        }
      },
      schedules: {
        filters: {
          periods: [{
            id: 'paymentDate',
            value: i18n.t('config:schedules.filters.periods.paymentDate')
          }]
        }
      },
      settings: {
        intranet: {
          dashboard: {
            period: {
              options: [{
                id: 'today',
                value: i18n.t('common:dateAndTime.today')
              }, {
                id: 'thisWeek',
                value: i18n.t('components:filterPeriod.thisWeek')
              }, {
                id: 'thisMonth',
                value: i18n.t('components:filterPeriod.thisMonth')
              }, {
                id: 'thisQuarterly',
                value: i18n.t('components:filterPeriod.thisTrimester')
              }, {
                id: 'thisTrimester',
                value: i18n.t('components:filterPeriod.thisTrimester')
              }, {
                id: 'thisSemester',
                value: i18n.t('components:filterPeriod.thisSemester')
              }, {
                id: 'thisSemester',
                value: i18n.t('components:filterPeriod.thisSemester')
              }, {
                id: 'thisYear',
                value: i18n.t('components:filterPeriod.thisYear')
              }, {
                id: 'thisFiscalYear',
                value: i18n.t('components:filterPeriod.thisFiscalYear')
              }, {
                id: 'yesterday',
                value: i18n.t('components:filterPeriod.yesterday')
              }, {
                id: 'lastWeek',
                value: i18n.t('components:filterPeriod.lastWeek')
              }, {
                id: 'lastMonth',
                value: i18n.t('components:filterPeriod.lastMonth')
              }, {
                id: 'lastTrimester',
                value: i18n.t('components:filterPeriod.lastTrimester')
              }, {
                id: 'lastSemester',
                value: i18n.t('components:filterPeriod.lastSemester')
              }, {
                id: 'lastYear',
                value: i18n.t('components:filterPeriod.lastYear')
              }, {
                id: 'lastFiscalYear',
                value: i18n.t('components:filterPeriod.lastFiscalYear')
              }, {
                id: 'untilToday',
                value: i18n.t('components:filterPeriod.untilToday')
              }, {
                id: 'thisFiscalPeriod',
                value: i18n.t('components:filterPeriod.thisFiscalYear')
              }, {
                id: 'tomorrow',
                value: i18n.t('components:filterPeriod.tomorrow')
              }, {
                id: 'nextWeek',
                value: i18n.t('components:filterPeriod.nextWeek')
              }, {
                id: 'nextMonth',
                value: i18n.t('components:filterPeriod.nextMonth')
              }, {
                id: 'nextTrimester',
                value: i18n.t('components:filterPeriod.nextTrimester')
              }, {
                id: 'nextSemester',
                value: i18n.t('components:filterPeriod.nextSemester')
              }, {
                id: 'nextYear',
                value: i18n.t('components:filterPeriod.nextYear')
              }, {
                id: 'nextFiscalYear',
                value: i18n.t('components:filterPeriod.nextFiscalYear')
              }]
            }
          },
          defaultOptions: [{
            id: 'notUsed',
            value: i18n.t('config:settings.intranet.defaultOptions.notUsed')
          }, {
            id: 'customized',
            value: i18n.t('wemailing:templates.state.values.false')
          }],
          homepage: {
            options: [{
              id: 'dashboard',
              value: i18n.t('components:bmRightsFilters.modules.miscellaneous.0.value')
            }, {
              id: 'actions',
              value: i18n.t('components:bmRightsFilters.modules.miscellaneous.1.value')
            }, {
              id: 'candidates',
              value: i18n.t('components:bmRightsFilters.modules.main.0.value')
            }, {
              id: 'resources',
              value: i18n.t('components:bmRightsFilters.modules.main.1.value')
            }, {
              id: 'products',
              value: i18n.t('components:bmRightsFilters.modules.main.8.value')
            }, {
              id: 'crm',
              value: i18n.t('components:bmRightsFilters.modules.main.2.moduleTitle')
            }, {
              id: 'opportunities',
              value: i18n.t('components:bmRightsFilters.modules.main.3.value')
            }, {
              id: 'positionings',
              value: i18n.t('components:bmRightsFilters.modules.main.3.subModules.0.value')
            }, {
              id: 'projects',
              value: i18n.t('components:bmRightsFilters.modules.main.4.value')
            }, {
              id: 'purchases',
              value: i18n.t('config:settings.intranet.homepage.options.purchases')
            }, {
              id: 'activityExpenses',
              value: i18n.t('components:bmRightsFilters.modules.main.5.moduleTitle')
            }, {
              id: 'planning',
              value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.0.value')
            }, {
              id: 'billing',
              value: i18n.t('components:bmRightsFilters.modules.main.6.moduleTitle')
            }, {
              id: 'reporting',
              value: i18n.t('components:bmRightsFilters.modules.planningAndReporting.1.value')
            }, {
              id: 'apps',
              value: i18n.t('config:settings.intranet.homepage.options.apps')
            }]
          },
          menu: {
            employee: [{
              id: 'resourceTimesReports',
              value: i18n.t('config:settings.intranet.menu.employee.resourceTimesReports')
            }, {
              id: 'resourceExpensesReports',
              value: i18n.t('config:settings.intranet.menu.employee.resourceExpensesReports')
            }, {
              id: 'resourceAbsencesReports',
              value: i18n.t('config:settings.intranet.menu.employee.resourceAbsencesReports')
            }, {
              id: 'resourceProjects',
              value: i18n.t('config:settings.intranet.menu.employee.resourceProjects')
            }, {
              id: 'resourceProviderInvoices',
              value: i18n.t('config:settings.intranet.menu.employee.resourceProviderInvoices')
            }],
            manager: [{
              id: 'actions',
              value: i18n.t('config:settings.intranet.menu.manager.actions')
            }, {
              id: 'candidates',
              value: i18n.t('config:settings.intranet.menu.manager.candidates')
            }, {
              id: 'resources',
              value: i18n.t('config:settings.intranet.menu.manager.resources')
            }, {
              id: 'products',
              value: i18n.t('config:settings.intranet.menu.manager.products')
            }, {
              id: 'crm',
              value: i18n.t('config:settings.intranet.menu.manager.crm')
            }, {
              id: 'opportunities',
              value: i18n.t('config:settings.intranet.menu.manager.opportunities')
            }, {
              id: 'positionings',
              value: i18n.t('config:settings.intranet.menu.manager.positionings')
            }, {
              id: 'projects',
              value: i18n.t('config:settings.intranet.menu.manager.projects')
            }, {
              id: 'purchases',
              value: i18n.t('config:settings.intranet.menu.manager.purchases')
            }, {
              id: 'activityExpenses',
              value: i18n.t('config:settings.intranet.menu.manager.activityExpenses')
            }, {
              id: 'planning',
              value: i18n.t('config:settings.intranet.menu.manager.planning')
            }, {
              id: 'billing',
              value: i18n.t('config:settings.intranet.menu.manager.billing')
            }, {
              id: 'reporting',
              value: i18n.t('config:settings.intranet.menu.manager.reporting')
            }, {
              id: 'customers',
              value: i18n.t('config:settings.intranet.menu.manager.customers')
            }, {
              id: _bm.MODULE_BANKING_TRANSACTIONS,
              value: i18n.t("config:settings.intranet.menu.manager.".concat(_bm.MODULE_BANKING_TRANSACTIONS))
            }, {
              id: 'labs',
              value: i18n.t('config:settings.intranet.menu.manager.labs')
            }]
          },
          theme: {
            options: [{
              id: 'light',
              value: i18n.t('config:settings.intranet.theme.options.light')
            }, {
              id: 'dark',
              value: i18n.t('config:settings.intranet.theme.options.dark')
            }, {
              id: 'system',
              value: i18n.t('config:settings.intranet.theme.options.system')
            }]
          }
        },
        targets: {
          periodicity: {
            abbr: [{
              id: 'weekly',
              value: i18n.t('common:dateAndTime.days.saturday.letter')
            }, {
              id: 'monthly',
              value: i18n.t('common:dateAndTime.days.tuesday.letter')
            }, {
              id: 'quarterly',
              value: i18n.t('common:dateAndTime.periods.quarterly.abbr')
            }, {
              id: 'semiAnnual',
              value: i18n.t('common:dateAndTime.days.saturday.letter')
            }, {
              id: 'annual',
              value: i18n.t('houraccounts:filters.validated.options.0.id')
            }],
            options: [{
              id: 'weekly',
              value: i18n.t('extractbi:frequency.typeOf.options.weekly')
            }, {
              id: 'monthly',
              value: i18n.t('extractbi:frequency.typeOf.options.monthly')
            }, {
              id: 'quarterly',
              value: i18n.t('config:agency.resources.advantageTypes.frequency.options.quarterly')
            }, {
              id: 'semiAnnual',
              value: i18n.t('config:agency.resources.advantageTypes.frequency.options.semiAnnual')
            }, {
              id: 'annual',
              value: i18n.t('config:agency.resources.advantageTypes.frequency.options.annual')
            }]
          }
        }
      },
      signature: {
        timesreports: {
          type: {
            defaultOptions: [{
              id: 'customer',
              value: i18n.t('config:signature.timesreports.type.defaultOptions.customer')
            }, {
              id: 'imported',
              value: i18n.t('config:signature.timesreports.type.defaultOptions.imported')
            }]
          }
        }
      },
      subscription: {
        options: {
          appesignature: {
            modal: {
              options: [{
                id: 100,
                value: i18n.t('subscription.options.appesignature.modal.options.0.value')
              }, {
                id: 250,
                value: i18n.t('subscription.options.appesignature.modal.options.1.value')
              }, {
                id: 500,
                value: i18n.t('subscription.options.appesignature.modal.options.2.value')
              }, {
                id: 1000,
                value: i18n.t('subscription.options.appesignature.modal.options.3.value')
              }]
            }
          }
        }
      },
      timesreport: {
        dayLabel: {
          '0': i18n.t('common:dateAndTime.days.sunday.letter'),
          '1': i18n.t('common:dateAndTime.days.monday.letter'),
          '2': i18n.t('common:dateAndTime.days.tuesday.letter'),
          '3': i18n.t('common:dateAndTime.days.wednesday.letter'),
          '4': i18n.t('common:dateAndTime.days.thursday.letter'),
          '5': i18n.t('common:dateAndTime.days.friday.letter'),
          '6': i18n.t('common:dateAndTime.days.saturday.letter')
        },
        proofCategory: [{
          id: 'signedProof',
          value: i18n.t('config:timesreport.proofCategory.signedProof')
        }, {
          id: 'other',
          value: i18n.t('config:agency.activityExpenses.ocrcategory.options.6')
        }, {
          id: 'signatureRequested',
          value: i18n.t('config:timesreport.proofCategory.signatureRequested')
        }, {
          id: 'unsignedProof',
          value: i18n.t('config:invoices.proofCategory.unsignedProof')
        }, {
          id: 'expense',
          value: i18n.t('config:invoices.proofCategory.expense')
        }],
        workplaceSettingsModal: {
          days: [{
            id: 1,
            value: i18n.t('extractbi:frequency.weeklyDays.options.monday')
          }, {
            id: 2,
            value: i18n.t('extractbi:frequency.weeklyDays.options.tuesday')
          }, {
            id: 3,
            value: i18n.t('extractbi:frequency.weeklyDays.options.wednesday')
          }, {
            id: 4,
            value: i18n.t('extractbi:frequency.weeklyDays.options.thursday')
          }, {
            id: 5,
            value: i18n.t('extractbi:frequency.weeklyDays.options.friday')
          }, {
            id: 6,
            value: i18n.t('extractbi:frequency.weeklyDays.options.saturday')
          }, {
            id: 0,
            value: i18n.t('extractbi:frequency.weeklyDays.options.sunday')
          }]
        }
      },
      todolists: {
        templates: {
          state: {
            values: [{
              id: '',
              value: i18n.t('common:indifferent')
            }, {
              id: '0',
              value: i18n.t('survey:view.state.offText')
            }, {
              id: '1',
              value: i18n.t('config:todolists.templates.state.values.1')
            }]
          },
          task: {
            types: [{
              id: 0,
              value: i18n.t('config:todolists.templates.task.types.0')
            }, {
              id: 1,
              value: i18n.t('config:todolists.templates.task.types.1')
            }]
          },
          profiles: [{
            id: 'candidate',
            value: i18n.t('models:candidate.name')
          }, {
            id: 'resource',
            value: i18n.t('models:employee.name')
          }, {
            id: 'product',
            value: i18n.t('models:product.name')
          }, {
            id: 'contract',
            value: i18n.t('models:contract.name')
          }, {
            id: 'company',
            value: i18n.t('models:company.name')
          }, {
            id: 'contact',
            value: i18n.t('models:contact.name')
          }, {
            id: 'opportunity',
            value: i18n.t('models:opportunity.name')
          }, {
            id: 'positioning',
            value: i18n.t('models:positioning.name')
          }, {
            id: 'quotation',
            value: i18n.t('models:quotation.name')
          }, {
            id: 'project',
            value: i18n.t('models:project.name')
          }, {
            id: 'delivery',
            value: i18n.t('models:delivery.name')
          }, {
            id: 'order',
            value: i18n.t('models:order.name')
          }, {
            id: 'invoice',
            value: i18n.t('models:invoice.name')
          }, {
            id: 'purchase',
            value: i18n.t('models:purchase.name')
          }, {
            id: 'payment',
            value: i18n.t('models:payment.name')
          }]
        }
      },
      validations: {
        documentTypes: [{
          id: 'absencesReport',
          value: i18n.t('wplanproduction:dataTypes.absences')
        }, {
          id: 'expensesReport',
          value: i18n.t('dataclosing:view.expensesreports.title')
        }, {
          id: 'timesReport',
          value: i18n.t('dataclosing:view.timesreports.title')
        }]
      },
      webhook: {
        entity: [{
          id: 'product',
          value: i18n.t('models:product.name')
        }, {
          id: 'candidate',
          value: i18n.t('models:candidate.name')
        }, {
          id: 'resource',
          value: i18n.t('models:employee.name')
        }, {
          id: 'advantage',
          value: i18n.t('models:advantage.name')
        }, {
          id: 'contract',
          value: i18n.t('models:contract.name')
        }, {
          id: 'purchase',
          value: i18n.t('models:purchase.name')
        }, {
          id: 'payment',
          value: i18n.t('models:payment.name')
        }, {
          id: 'opportunity',
          value: i18n.t('models:opportunity.name')
        }, {
          id: 'app',
          value: i18n.t('models:app.name')
        }, {
          id: 'positioning',
          value: i18n.t('models:positioning.name')
        }, {
          id: 'order',
          value: i18n.t('models:order.name')
        }, {
          id: 'invoice',
          value: i18n.t('models:invoice.name')
        }, {
          id: 'contact',
          value: i18n.t('models:contact.name')
        }, {
          id: 'company',
          value: i18n.t('models:company.name')
        }, {
          id: 'project',
          value: i18n.t('models:project.name')
        }, {
          id: 'delivery',
          value: i18n.t('models:delivery.name')
        }, {
          id: 'inactivity',
          value: i18n.t('models:inactivity.name')
        }, {
          id: 'groupment',
          value: i18n.t('models:groupment.name')
        }, {
          id: 'businessunit',
          value: i18n.t('models:businessunit.name')
        }, {
          id: 'agency',
          value: i18n.t('models:agency.name')
        }, {
          id: 'pole',
          value: i18n.t('models:pole.name')
        }, {
          id: 'role',
          value: i18n.t('models:role.name')
        }, {
          id: 'expensesreport',
          value: i18n.t('models:expensesreport.name')
        }, {
          id: 'timesreport',
          value: i18n.t('models:timesreport.name')
        }, {
          id: 'absencesreport',
          value: i18n.t('models:absencesreport.name')
        }, {
          id: 'action',
          value: i18n.t('models:action.name')
        }, {
          id: 'actiontemplate',
          value: i18n.t('models:actiontemplate.name')
        }, {
          id: 'quotation',
          value: i18n.t('models:quotation.name')
        }, {
          id: 'validation',
          value: i18n.t('models:validation.name')
        }, {
          id: 'vendor',
          value: i18n.t('models:vendor.name'),
          createDisable: true,
          deleteDisable: true
        }, {
          id: 'customer',
          value: i18n.t('models:customer.name')
        }, {
          id: 'architecture',
          value: i18n.t('models:architecture.name'),
          createDisable: true,
          deleteDisable: true
        }, {
          id: 'featureFlag',
          value: i18n.t('models:featureflag.name'),
          createDisable: true,
          deleteDisable: true
        }, {
          id: 'flag',
          value: i18n.t('models:flag.name')
        }, {
          id: 'target',
          value: i18n.t('models:target.name')
        }, {
          id: 'thread',
          value: i18n.t('common:thread.title')
        }, {
          id: 'followeddocument',
          value: i18n.t('models:followeddocument.name')
        }, {
          id: 'appentity',
          value: i18n.t('models:appentity.name')
        }]
      },
      providerinvoices: {
        filters: {
          periods: [{
            id: 'invoiceDate',
            value: i18n.t('providerinvoices:filters.invoiceDate')
          }, {
            id: 'invoicePeriod',
            value: i18n.t('providerinvoices:filters.invoicePeriod')
          }]
        }
      }
    };
  };
});