define("boondmanager/pods/components/octane/bm-feature-is-enabled/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P3C+uUgu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isEnabled\"]]],null,[[\"default\"],[{\"statements\":[[18,1,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-feature-is-enabled/template.hbs"
  });
});