define("boondmanager/pods/private/403/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "05vuDnc1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bml-error-page\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bml-error_img bml-error_img-403\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,2],[\"img/error-403.png\",[30,[36,1],[\"messages:error.403.title\"],null]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"bml-error_content\"],[12],[2,\"\\n\\t\\t\"],[10,\"h1\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"messages:error.403.title\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"p\"],[14,0,\"bml-error-description\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"messages:error.403.message\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"type\"]],[30,[36,3],[[30,[36,0],[\"messages:error.403.params.\",[32,0,[\"type\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bml-error-next-step\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[[30,[36,0],[\"messages:error.403.params.\",[32,0,[\"type\"]]],null]],null]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[10,\"ul\"],[14,0,\"bml-error-actions\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-btn/back\",[[24,0,\"bmb-large\"]],[[],[]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[14,0,\"bml-error_content-page-name bm-text-secondary\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[12],[1,[30,[36,1],[\"messages:error.403.pageName\"],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"img-relative\",\"t-exists\",\"and\",\"if\"]}",
    "moduleName": "boondmanager/pods/private/403/template.hbs"
  });
});